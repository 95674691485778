import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Strings from '../../strings';
import { getName } from '../../helpers';
class Specs extends Component {
    render() {
        let lang = localStorage.getItem('lang')
        let { providers, facility, activeDoctors } = this.props
        let { specalities } = providers
        let { selectedSpecialties } = facility
        let doctorSpec = activeDoctors.map(rec => {
            return { specs: rec.clinics[facility.facilityId], doctor: rec.id }
        })

        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings.Specialities
                    }
                </div>
                {
                    selectedSpecialties.map(item => {
                        let speciality = specalities.find(spec => spec.id === item)
                        return (
                            <Link key={item} className='specsItem' to={`/searchView?search_type=doctors&facility=${facility.facilityId}&speciality=${item}`} >
                                <div className='spec-name'>
                                    {getName(speciality, "name")}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div >
                                        {doctorSpec.filter(rec => rec.specs?.includes(item)).length}
                                    </div>
                                    <Icon name={lang === 'ar' ? 'arrow left' : 'arrow right'}></Icon>
                                </div>

                            </Link>
                        )
                    })
                }


            </div>
        );
    }
}

export default Specs;