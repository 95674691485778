import React, { useEffect, useState } from 'react';
import { Icon, Dimmer, TextArea } from 'semantic-ui-react'
import { firestore } from '../fire';

const ReviewModal = (props) => {
    let [step, setStep] = useState(0)
    let [comment, setComment] = useState(false)
    let [score, setScore] = useState({
        score_0: 2,
        score_1: 2,
        score_2: 2,
        score_3: 2
    })

    let { recommend, providers, user, review } = props
    useEffect(() => {
        if (review) {
            setScore(review.score)
            setStep(1)
        }
    }, [])
    let doctor = providers.find(rec => rec.id === recommend?.doctor)
    let questionsArray = [
        { question: 'Doctor listens and answers my questions.', score: 'score_1' },
        { question: 'Doctor clearly explains my medical condition.', score: 'score_2' },
        { question: 'Doctor clearly explains treatment plan.', score: 'score_3' },

    ]
    if (!comment) {
        comment = `I love the experince with ${doctor?.name}`
    }
    return (
        <div>
            <Dimmer active={true} page>
                <div className='recommendView'>
                    <Icon
                        style={{ display: 'flex', fontSize: '18px', padding: '12px', cursor: 'pointer' }}
                        onClick={() => props.dismiss()} name='times' />
                    {
                        step === 0 ?
                            <>
                                <div style={{ paddingTop: '35%' }}>
                                    <img style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%' }} src={doctor?.profilePicture} />
                                </div>
                                <div style={{ padding: '20px 40px', fontSize: '28px' }}>
                                    {`How likley would you recommend ${doctor?.name} to others`}

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', fontSize: '22px' }} >
                                    {
                                        [0, 1, 2, 3, 4].map(item => {
                                            return (
                                                <Icon
                                                    key={item}
                                                    name='star outline'
                                                    onClick={() => {
                                                        let newScore = JSON.parse(JSON.stringify(score))
                                                        newScore.score_0 = item
                                                        setScore(newScore)
                                                    }}
                                                    className={score.score_0 >= item ? 'starts_fill' : 'starts'} />
                                            )

                                        })
                                    }
                                </div>
                                <div style={{ fontSize: '22px', color: '#cacaca', padding: '12px' }}>
                                    Tap Stars
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div onClick={() => setStep(1)} style={{ padding: '12px 20%', marginTop: '30%', fontSize: '16px', backgroundColor: 'var(--themeColor)', color: '#fff', borderRadius: '12px', fontFamily: 'tfBold', marginBottom: '8px' }}>
                                        Submit
                                </div>
                                </div>


                            </>
                            :
                            <div style={{ color: '#4d4d4d', textAlign: 'initial', padding: '12px' }}>
                                <div style={{ borderBottom: '1px dashed', padding: '16px 0' }}>
                                    <div style={{ fontSize: '26px', padding: '8px 0' }}> Great Ahjmed!</div>
                                    <div style={{ fontSize: '18px' }}> Tell us more details about your experience </div>

                                </div>
                                <div>
                                    {
                                        questionsArray.map(record => {
                                            return (
                                                <div key={record.score} style={{ padding: '10px 0' }}>
                                                    <div style={{ fontSize: '16px', fontFamily: 'tfRegular', padding: '8px 0' }}>{record.question}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        {
                                                            [0, 1, 2, 3, 4].map(item => {
                                                                return (
                                                                    <Icon
                                                                        key={item}
                                                                        name='star outline'
                                                                        onClick={() => {
                                                                            let newScore = JSON.parse(JSON.stringify(score))
                                                                            newScore[record.score] = item
                                                                            setScore(newScore)
                                                                        }}
                                                                        className={score[record.score] >= item ? 'starts_fill' : 'starts'} />
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }


                                </div>
                                <TextArea value={comment}
                                    onChange={(e, { value }) => setComment(value)}
                                    style={{ margin: '12px 0', minHeight: '12vh', width: '100%', border: '1px solid #cacaca', borderRadius: '8px', padding: '4px' }} />

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div onClick={() => {
                                        let ref = firestore().collection('reviews')
                                        let id = review ? review.id : ref.doc().id
                                        let post = {
                                            bookingId: recommend?.id,
                                            comment: comment,
                                            created: Date.now(),
                                            doctorId: recommend.doctor,
                                            facilityId: recommend.facilityId,
                                            patientId: recommend.patient,
                                            patientName: recommend.patientName,
                                            profilePicture: user?.profilePicture || '',
                                            score: score
                                        }
                                        firestore().collection('reviews').doc(id).set(post, { merge: true }).then(() => {
                                            firestore().collection('bookings').doc(recommend?.id).update({
                                                recommended: true
                                            }).then(() => {
                                                props.dismiss()
                                            })
                                        })
                                    }}
                                        style={{
                                            padding: '12px 20%',
                                            marginTop: '30%',
                                            fontSize: '16px',
                                            backgroundColor: 'var(--themeColor)',
                                            color: '#fff',
                                            borderRadius: '12px',
                                            fontFamily: 'tfBold',
                                            marginBottom: '8px'
                                        }}>
                                        Save and continue
                                    </div>

                                </div>
                            </div>
                    }



                </div>

            </Dimmer>
        </div>
    );
};

export default ReviewModal;