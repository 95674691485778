import { createAction } from 'redux-action';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_FACILITIES = 'GET_FACILITIES';
export const GET_SPECS = 'GET_SPECS';
export const GET_INSURNCES = 'GET_INSURNCES';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_CONDITIONS = 'GET_CONDITIONS';
export const GET_TOP_SPECS = 'GET_TOP_SPECS';
export const GET_AMENITIES = 'GET_AMENITIES';

export const getProviders = createAction(GET_PROVIDERS, (list) => {
    return list
});
export const getFacilities = createAction(GET_FACILITIES, (list) => {
    return list
});
export const getSpecs = createAction(GET_SPECS, (list) => {
    return list
});
export const getAmenities = createAction(GET_AMENITIES, (list) => {
    return list
});
export const getInsurnces = createAction(GET_INSURNCES, (list) => {
    return list
});
export const getServices = createAction(GET_SERVICES, (list) => {
    return list
});
export const getTopSpecs = createAction(GET_TOP_SPECS, (list) => {
    return list
});
export const getConditions = createAction(GET_CONDITIONS, (list) => {
    return list
});

const initial = {};


let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_PROVIDERS:
            return {
                ...state,
                providers: action.payload,
            };
        case GET_FACILITIES:
            return {
                ...state,
                facilities: action.payload
            }
        case GET_SPECS:
            return {
                ...state,
                specalities: action.payload || []
            }
        case GET_INSURNCES:
            return {
                ...state,
                insurnces: action.payload || []
            }
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload || []
            }
        case GET_TOP_SPECS:
            return {
                ...state,
                topSpecalties: action.payload || []
            }
        case GET_AMENITIES:
            return {
                ...state,
                amenities: action.payload || []
            }
        case GET_CONDITIONS:
            return {
                ...state,
                conditions: action.payload || []
            }
        default:
            return state;
    }
};
export default ex