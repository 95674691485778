import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import Strings from './strings'
function Footer(props) {
    let elements = [{ name: "Home", icon: "home", path: '/' },
    { name: "Appointments", icon: "calendar alternate outline", path: '/appointments' },
    { name: "Profile", icon: "user outline", path: '/profile' }]
    let [activePath, setActivePath] = useState(window.location.pathname)

    return (
        <div style={{ position: 'sticky', bottom: '0', borderTop: '1px solid #cacaca', backgroundColor: '#fff', width: '100%', display: 'flex', justifyContent: 'space-around', padding: '12px 0 18px' }}>
            {
                elements.map(item => {
                    return (
                        <Link to={item.path} key={item.name} onClick={() => setActivePath(item.path)}>
                            <Icon style={{ fontSize: '20px', color: activePath === item.path ? 'var(--themeColor)' : '#cacaca' }} name={item.icon} />
                            <div style={{ fontSize: '12px',fontFamily:'tflight', color: activePath === item.path ? 'var(--themeColor)' : '#cacaca' }}>{Strings[item.name]}</div>
                        </Link>
                    )
                })
            }
        </div>
    );
}

export default Footer;