import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Button, Icon, Modal, ModalHeader } from 'semantic-ui-react'
import Strings from '../../strings';
import { getName } from '../../helpers'
function getNumber(clinic) {
    if (!clinic) {
        return ''
    }
    if (clinic.tollNumber && clinic.tollNumber.length) {
        let number = clinic.tollNumber[0]
        return (number?.country.replace('UAE', '').replace('OMR', '') + number?.code + number?.number)
    }
    if (clinic.landLineNumbers && clinic.landLineNumbers.length) {
        let number = clinic.landLineNumbers[0]
        return (number?.country.replace('UAE', '').replace('OMR', '') + number?.code + number?.number)
    }
}

function RecentViewdCard(props) {
    let { doctor, specalities, facilities, selectedSpec, user } = props
    let { clinics } = doctor
    let [alert, setAlert] = useState(false)
    let specKey = Object.keys(clinics).map(key => {
        if (clinics[key].length) {
            return clinics[key][0]
        }

    })[0]
    let clinic = facilities?.find(rec => rec.facilityId === (clinics ? Object.keys(clinics)[0] : 'aaa'))
    let specName = specalities.find(spec => spec.id === (selectedSpec || specKey))
    let bookingActive = doctor?.bookingActive && Object.keys(doctor.bookingActive).filter(rec => Object.keys(doctor.clinics).includes(rec) && facilities?.find(clinic => clinic.facilityId === rec)?.bookingActive)
    let notAllowed = user?.userBookings?.filter(rec => ["new", "pending", "accepted"].includes(rec.status) && (rec.doctor === doctor.id))?.length > 0

    return (
        <div className='card' style={{ display: 'flex' }} >
            {
                alert ?
                    <Modal open={true}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '8px' }}>
                            <div style={{ fontSize: '18px', fontFamily: 'tfBold', color: '#4d4d4d', padding: '8px' }}> {Strings.Alert}</div>
                            <div style={{ padding: '8px', fontSize: '16px' }}> {Strings['You have an active request with '] + getName(doctor, "name")} </div>
                            <div style={{ padding: '8px' }}>
                                <Button style={{ backgroundColor: 'var(--themeColor)', color: '#fff' }} onClick={() => setAlert(false)}>{Strings.OK}</Button>

                            </div>
                        </div>
                    </Modal> : ''


            }
            <div className='cardDoctors' >
                <Link to={'/doctor-profile?key=' + doctor.id} className='doctorInfo' >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className='logo' src={doctor?.profilePicture} />
                        <div style={{ textAlign: 'initial', padding: '0 12px' }}>
                            <div className='name'>
                                {getName(doctor, "name")}
                            </div>
                            <div style={{ fontSize: '14px', color: '#cacaca', textAlign: 'initial' }}>
                                {[getName(specName, "name"), Strings[doctor.title]]?.filter(r=>!!r)?.join(" - ")}
                            </div>
                        </div>

                    </div>
                    {
                        doctor?.recommend?.starCount ?
                            <div style={{ fontFamily: 'tfBold', fontSize: '16px', color: 'var(--themeColor)' }}>
                                <Icon name='thumbs up' />
                                <div>{doctor?.recommend?.starCount}</div>
                            </div>
                            : ''

                    }

                </Link>
                {
                    bookingActive?.length ?
                        <Link
                            to={notAllowed ? '' : '/book?doctor_id=' + doctor.id}
                            onClick={() => {
                                setAlert(true)
                                // if (notAllowed) { alert('You have an active request with Dr.' + doctor.name) }
                            }}
                            className='bookBtn'
                        >
                            {Strings.book}
                        </Link>
                        : <a href={"tel:" + getNumber(clinic).replace(/ /g, '')} className='callBtn'
                           
                        >{Strings.call}
                        </a>
                }


            </div>
        </div >
    );
}

export default RecentViewdCard;