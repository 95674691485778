import React, { Component } from 'react';
import { Button, Icon, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react'
import { firestore } from '../fire';
import Strings from '../strings';
class Payments extends Component {
    state = {}
    render() {
        let { confirm, loading } = this.state
        let { user } = this.props
        let { userCards } = user
        return (
            <div style={{ height: '100%', padding: '12px 8px' }}>
                <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '22px' }}>{Strings['Payment Cards']}</div>

                <div style={{ padding: '8px 0' }}>
                    {
                        userCards && Object.keys(userCards).length ? Object.keys(userCards).map(cardId => {
                            let card = userCards[cardId]
                            return (
                                <div
                                    onClick={() => {
                                        // this.setState({ selectedCard: rec, selectCard: false })
                                    }}
                                    key={cardId}
                                    style={{ display: 'flex', padding: '10px', justifyContent: 'space-between', border: '1px solid #cacaca', borderRadius: '8px', margin: '8px', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon name='credit card' style={{ color: 'var(--themeColor)', margin: '0', fontSize: "22px" }} />
                                        <div style={{ padding: '0 10px' }}>
                                            <div style={{ padding: '2px 0', fontFamily: 'tfMedium', fontSize: "16px" }}>{`XXXX XXXX${card?.savedCard?.last_four}`}</div>
                                            <div style={{ fontFamily: 'tfMedium', fontSize: '12px' }}>{card?.savedCard?.brand}</div>
                                        </div>
                                    </div>
                                    <Icon onClick={() => this.setState({ confirm: card })} style={{ padding: '4px', fontSize: '18px', color: 'red' }} name='times' />
                                </div>
                            )
                        })

                            :
                            <div style={{ paddingTop: '50%', display: 'flex', justifyContent: 'center', fontFamily: 'tfBold', fontSize: '18px' }}> {Strings['No saved cards found']}</div>
                    }
                </div>

                {
                    confirm && <Modal open={true}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '8px' }}>
                            <div style={{ fontSize: '18px', fontFamily: 'tfBold', color: '#4d4d4d', padding: '8px' }}> {Strings.Alert}</div>
                            <div style={{ padding: '8px', fontSize: '16px' }}> {`Please confirm deleting this card XXXX${confirm?.savedCard?.last_four}`} </div>
                            <div style={{ padding: '8px' }}>
                                <Button color='red' onClick={() => this.setState({ confirm: false })}>{Strings.Cancel}</Button>
                                <Button loading={loading} onClick={() => {
                                    this.setState({ loading: true }, () => {
                                        firestore().collection('users').doc(user.uid).update({
                                            [`userCards.${confirm.savedCard.id}`]: firestore.FieldValue.delete()
                                        }).then(() => {
                                            this.setState({ loading: false, confirm: false })
                                        })
                                    })
                                }} style={{ backgroundColor: 'var(--themeColor)', color: '#fff' }}>{Strings.Confirm}</Button>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

export default Payments;