import LocalizedStrings from 'react-localization'

const Strings = new LocalizedStrings({
    en: {
        nearbyClinics: " Branches",
        locations: " Locations",
        showAll: "show all",
        km: "KM",
        recentViewed: "Recently viewed",
        specialties: "Specialties",
        Specialties: "Specialties",
        Doctors: "Doctors",
        Facilities: "Facilities",
        book: 'Book Appointment',
        call: "Call",
        "Open Now": "Open Now",
        "Closed Now": "Closed Now",
        "Consultant": "Consultant",
        "G.P": "General Practice",
        "Specialist": "Specialist",
        Home: "Home",
        Appointments: "Appointments",
        Profile: "Profile",
        views: "views",
        about: "About",
        Specialities: "Specialities",
        Timings: "Timings",
        "open 24h": "open 24h",
        from: "From",
        to: "To",
        Closed: "Closed",
        Saturday: "Saturday",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thuresday: "Thuresday",
        Friday: "Friday",
        today: 'Today',
        Services: "Services",
        Insurances: "Insurances",
        Amenities: "Amenities",
        showLess: "Show Less",
        "Link Copied to your clipboard!": "Link Copied to your clipboard!",
        recommend: "Recommend",
        recommends: "Recommends",
        Bio: "Bio",
        general: "General",
        "language spoken": "language spoken",
        Nationality: 'Nationality',
        Gender: "Gender",
        Male: "Male",
        Female: "Female",
        "Can help with": "Can help with",
        "Clinics Locations": "Practice location",
        "Facility Name": "Facility Name",
        "Address": "Address",
        "Doctor Consultation Fees": "Doctor Consultation Fees",
        Professional: "Professional",
        Experince: "Experince",
        Fellowship: "Fellowship",
        Residency: "Residency",
        "Medical School": "Medical School",
        Specialty: "Specialty",
        "Top Specialties": "Top Specialties",
        doctor: "doctor",
        facility: "facility",
        specialty: "specialty",
        "My insurance": "My insurance",
        Languages: "Languages",
        Reset: "Reset",
        OK: "OK",
        Information: "Information",
        Insurance: "Insurance",
        Vouchers: "Vouchers",
        Feedback: "Feedback",
        "No Feedbacks yet!": "No Feedbacks yet!",
        Back: "Back",
        Hi: "Hi",
        bookingConfirmed: 'Bookings Confirmed',
        Favourite: "Favourite",
        Payment: "Payment",
        Language: "Language",
        Logout: "Logout",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Contact Number": "Contact Number",
        "Email Address": "Email Address",
        "Date Of Birth": "Date Of Birth",
        "Nationality": "Nationality",
        "+Add Insurance": "+Add Insurance",
        "Personal ID": "Personal ID",
        "+Add your ID": "+Add your ID",
        "Add Insurance": "Add Insurance",
        "Insurance Company*": "Insurance Company*",
        "Insurance Plan*": "Insurance Plan*",
        "Policy Number*": "Policy Number*",
        "Expiration Date*": "Expiration Date*",
        "Upload insurance Card": "Upload insurance Card",
        "*upload insurance card with good quality": "*upload insurance card with good quality",
        "Upload Insurance card [Front]": "Upload Insurance card [Front]",
        "Upload Insurance card [Back]": "Upload Insurance card [Back]",
        "Edit": "Edit",
        Cancel: "Cancel",
        Save: "Save",
        "Add ID": "Add ID",
        "Upload Personal ID Card": "Upload Personal ID Card",
        "*upload Personal card with good quality": "*upload Personal card with good quality",
        "Upload Personal card [Front]": "Upload Personal card [Front]",
        "Upload Personal card [Back]": "Upload Personal card [Back]",
        "This voucher is already redeemed!": "This voucher is already redeemed!",
        "Your voucher has been redeemed": "Your voucher has been redeemed",
        "Redeemed": "Redeemed",
        "Voucher is successfuly added to your profile": "Voucher is successfuly added to your profile",
        "This voucher expired!": "This voucher expired!",
        "Please enter valid Promo code!": "Please enter valid Promo code!",
        Get: "Get",
        "Promo code here": "Promo code here",
        Reviews: "Reviews",
        "Write a review": "Write a review",
        Confirm: "Confirm",
        Alert: "Alert",
        "No saved cards found": "No saved cards found",
        "Active": "Active",
        "Previous": "Previous",
        "Canceled": "Canceled",
        Clinic: "Clinic",
        Patient: "Patient",
        Service: "Service",
        Date: "Date",
        Status: "Status",
        "New": "New",
        "Confirmed": "Confirmed",
        "Pending": "Pending",
        "Arrived": "Arrived",
        Done: "Done",
        Join: "Attend",
        "waiting for doctor...": "waiting for doctor...",
        Reschedule: "Reschedule",
        "Book again": "Book again",
        "Tap Stars to review Doctor": "Tap Stars to review Doctor",
        "No Appointments Found!": "No Appointments Found!",
        "Find your doctor and book your appointment easily!": "Find your doctor and book your appointment easily!",
        Search: "Search",
        "Are you sure you want to cancel the appointment with Dr.": "Are you sure you want to cancel the appointment with Dr.",
        "You have requested an appointment with Dr.": "You have requested an appointment with Dr.",
        ", Are you sure?": ", Are you sure?",
        "Don't Cancel": "Don't Cancel",
        "Select Facility": "Select Facility",
        'Select Service': 'Select Service',
        'Select Date': 'Select Date',
        'For whom': 'For whom',
        'Payment Option': 'Payment Option',
        'CheckOut': 'CheckOut',
        Next: "Next",
        'Proceed to payment': "Proceed to payment",
        "Reason of the visit": "Reason of the visit",
        "Final confirmation will be from clinic side": "Final confirmation will be from clinic side",
        "No slots available": "No slots available",
        "Morning": "Morning ☀️",
        "Afternoon": "Afternoon 🌝",
        "Evening": "Evening 🌑",
        "Full Name": "Full Name",
        Cash: "Cash Payment",
        Card: "Online Payment",
        Provider: "Provider",
        Providers: "Providers",
        Facility: "Facility",
        "Date and Time": "Date and Time",
        "Booking For": "Booking For",
        Change: "Change",
        "Total Amount": "Total Amount",
        "Select Card": "Select Card",
        "Use New Card": "Use New Card",
        "Payment Method": "Payment Method",
        "You have rescheduled an appointment with Dr.": "You have rescheduled an appointment with Dr.",
        "Use New Card": "Use New Card",
        "Add New Insurance": "Add New Insurance",
        "You have an active request with ": "You have an active request with ",
        delete: "Delete",
        "Company Name": "Company Name",
        "Staff Number": "Staff Number",
        "Payment Cards": "Payment Cards",
        "Select Doctor": "Select Doctor",
        "You have to login to start using this feature": "Please Login to login to start using this feature",
        login: "Login",
        verify: "Verify",
        verifyCode: "Verify Code",
        "Continue as a guest": "Continue as a guest",
        "one last step to upgrade your experince": "one last step to upgrade your experince",
        "Full Name": "Full Name",
        submit: "Submit",
        "Doctor is waiting for you": 'Doctor is waiting for you',

        Nationalities: "Nationalities",
        "Austria": "Austria",
        "Australia": "Australia",
        "Aruba": "Aruba",
        "Argentina": "Argentina",
        "Antigua and Barbuda": "Antigua and Barbuda",
        "Angola": "Angola",
        "Andorra": "Andorra",
        "Algeria": "Algeria",
        "Afghanistan": "Afghanistan",
        "Albania": "Albania",
        "Burma": "Burma",
        "BurkinaFaso": "BurkinaFaso",
        "Bulgaria": "Bulgaria",
        "Brazil": "Brazil",
        "Botswana": "Botswana",
        "Bosnia and Herzegovina": "Bosnia and Herzegovina",
        "Bolivia": "Bolivia",
        "Bhutan": "Bhutan",
        "Benin": "Benin",
        "Belize": "Belize",
        "Belgium": "Belgium",
        "Belarus": "Belarus",
        "Barbados": "Barbados",
        "Bangladesh": "Bangladesh",
        "Bahrain": "Bahrain",
        "Bahamas": "Bahamas",
        "Czechia": "Czechia",
        "Cyprus": "Cyprus",
        "Curacao": "Curacao",
        "Cuba": "Cuba",
        "Croatia": "Croatia",
        "Cote d'Ivoire": "Cote d'Ivoire",
        "Costa Rica": "Costa Rica",
        "Congo": "Congo",
        "Comoros": "Comoros",
        "Colombia": "Colombia",
        "China": "China",
        "Chile": "Chile",
        "Chad": "Chad",
        "Central African Republic": "Central African Republic",
        "Cabo Verde": "Cabo Verde",
        "Canada": "Canada",
        "Cameroon": "Cameroon",
        "Cambodia": "Cambodia",
        "Denmark": "Denmark",
        "Djibouti": "Djibouti",
        "Dominica": "Dominica",
        "Dominican Republic": "Dominican Republic",
        "Ecuador": "Ecuador",
        "Egypt": "Egypt",
        "ElSalvador": "ElSalvador",
        "Equatorial Guinea": "Equatorial Guinea",
        "Eritrea": "Eritrea",
        "Estonia": "Estonia",
        "Ethiopia": "Ethiopia",
        "Fiji": "Fiji",
        "Finland": "Finland",
        "France": "France",
        "Gabon": "Gabon",
        "Gambia": "Gambia",
        "Georgia": "Georgia",
        "Germany": "Germany",
        "Ghana": "Ghana",
        "Greece": "Greece",
        "Grenada": "Grenada",
        "Guatemala": "Guatemala",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Guinea-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haiti",
        "HolySee": "HolySee",
        "Honduras": "Honduras",
        "Hong Kong": "Hong Kong",
        "Hungary": "Hungary",
        "Iceland": "Iceland",
        "India": "India",
        "Indonesia": "Indonesia",
        "Iran": "Iran",
        "Iraq": "Iraq",
        "Ireland": "Ireland",
        "Italy": "Italy",
        "Palestine": "Palestine",
        "Jamaica": "Jamaica",
        "Japan": "Japan",
        "Jordan": "Jordan",
        "Kazakhstan": "Kazakhstan",
        "Kenya": "Kenya",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuwait",
        "Kyrgyzstan": "Kyrgyzstan",
        "Laos": "Laos",
        "Latvia": "Latvia",
        "Lebanon": "Lebanon",
        "Lesotho": "Lesotho",
        "Liberia": "Liberia",
        "Libya": "Libya",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lithuania",
        "Luxembourg": "Luxembourg",
        "Macau": "Macau",
        "Macedonia": "Macedonia",
        "Madagascar": "Madagascar",
        "Malawi": "Malawi",
        "Malaysia": "Malaysia",
        "Maldives": "Maldives",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Marshall Islands",
        "Mauritania": "Mauritania",
        "Mauritius": "Mauritius",
        "Mexico": "Mexico",
        "Micronesia": "Micronesia",
        "Moldova": "Moldova",
        "Monaco": "Monaco",
        "Mongolia": "Mongolia",
        "Montenegro": "Montenegro",
        "Morocco": "Morocco",
        "Mozambique": "Mozambique",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Netherlands",
        "New Zealand": "New Zealand",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "North Korea": "North Korea",
        "Norway": "Norway",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Panama": "Panama",
        "Papua New Guinea": "Papua New Guinea",
        "Paraguay": "Paraguay",
        "Peru": "Peru",
        "Philippines": "Philippines",
        "Poland": "Poland",
        "Portugal": "Portugal",
        "Qatar": "Qatar",
        "Romania": "Romania",
        "Russia": "Russia",
        "Rwanda": "Rwanda",
        "Saint Kitts and Nevis": "Saint Kitts and Nevis",
        "Saint Lucia": "Saint Lucia",
        "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "Siloni": "Siloni",
        "Azerbaijan": "Azerbaijan",
        "Sao Tome and Principe": "Sao Tome and Principe",
        "Saudi Arabia": "Saudi Arabia",
        "Senegal": "Senegal",
        "Serbia": "Serbia",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapore",
        "Sint Maarten": "Sint Maarten",
        "Slovakia": "Slovakia",
        "Slovenia": "Slovenia",
        "Solomon Islands": "Solomon Islands",
        "Somalia": "Somalia",
        "South Africa": "South Africa",
        "South Korea": "South Korea",
        "South Sudan": "South Sudan",
        "Spain": "Spain",
        "SriLanka": "SriLanka",
        "Sudan": "Sudan",
        "Suriname": "Suriname",
        "Swaziland": "Swaziland",
        "Sweden": "Sweden",
        "Switzerland": "Switzerland",
        "Syria": "Syria",
        "Taiwan": "Taiwan",
        "Tajikistan": "Tajikistan",
        "Tanzania": "Tanzania",
        "Thailand": "Thailand",
        "Timor-Leste": "Timor-Leste",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad and Tobago",
        "Tunisia": "Tunisia",
        "Turkey": "Turkey",
        "Turkmenistan": "Turkmenistan",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ukraine",
        "United Arab Emirates": "United Arab Emirates",
        "United Kingdom": "United Kingdom",
        "United States": "United States",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Uzbekistan",
        "Vanuatu": "Vanuatu",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Yemen": "Yemen",
        "Zambia": "Zambia",
        "Zimbabwe": "Zimbabwe",
        "Armenia": "Armenia",

        //languages
        "German": "German",
        "English": "English",
        "French": "French",
        "Arabic": "Arabic",
        "Persian (Farsi)": "Persian (Farsi)",
        "Urdu": "Urdu",
        "Greek": "Greek",
        "Turkish": "Turkish",
        "Portuguese": "Portuguese",
        "Hindi": "Hindi",
        "Spanish": "Spanish",
        "Russian": "Russian",
        "Kashmiri": "Kashmiri",
        "Kannada": "Kannada",
        "Marathi": "Marathi",
        "Malay": "Malay",
        "Tamil": "Tamil",
        "Gujarati": "Gujarati",
        "Punjabi": "Punjabi",
        "Romanian": "Romanian",
        "Italian": "Italian",
        "Swedish": "Swedish",
        "Sindhi": "Sindhi",
        "Bengali": "Bengali",
        "Telugu": "Telugu",
        "Kiswahili(Swahili)": "Kiswahili(Swahili)",
        "Afrikaans": "Afrikaans",
        "Slovak": "Slovak",
        "Czech": "Czech",
        "Korean": "Korean",
        "Malayalam": "Malayalam",
        "Tagalog": "Tagalog",
        "Konkani": "Konkani",
        "Dutch": "Dutch",
        "Yoruba": "Yoruba",
        "Polish": "Polish",
        "Japanese": "Japanese",
        "Chinese": "Chinese",
        "Pakistani": "Pakistani",
        "Danish": "Danish",
        "Farsi": "Farsi",
        "Indian": "Indian",
        "Gujrati": "Gujrati",
        "Philipino": "Philipino",
        "Armenian": "Armenian",
        "Finnish": "Finnish",
        "Croatian": "Croatian",
        "Persian": "Persian",
        "Norwegian": "Norwegian",
        "Hindu": "Hindu",
        "Pashto": "Pashto",
        "Maltese": "Maltese",
        "Scandinavian": "Scandinavian",
        "Hungarian": "Hungarian",
        "Catalan": "Catalan",
        "Malaysian": "Malaysian",
        "Swiss": "Swiss",
        "Panjabi": "Panjabi",
        "Bulgarian": "Bulgarian",
        "Vietnamese": 'Vietnamese',

    },
    ar: {
        nearbyClinics: "الفروع",
        locations:'اماكن العيادات',
        showAll: "عرض المزيد",
        km: "km",
        recentViewed: "شوهد مؤخرآ",
        specialties: "التخصصات",
        Specialties: "تخصصات",
        Doctors: "اطباء",
        Facilities: "منشأت",
        book: "احجز موعد",
        call: "اتصال",
        "Open Now": "مفتوح الآن",
        "Closed Now": "مغلق الآن",
        "Consultant": "استشاري",
        "G.P": "ممارس عام",
        "Specialist": "اخصائي",
        Home: "الرئيسية",
        Appointments: "المواعيد",
        Profile: "الملف الشخصي",
        views: "مشاهدة",
        about: "نبذة",
        Specialities: "التخصصات",
        Timings: "مواعيد العمل",
        "open 24h": "مفتوح ٢٤ ساعة",
        from: "من",
        to: "الى",
        Closed: "مغلق",
        Saturday: "السبت",
        Sunday: "الاحد",
        Monday: "الاثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الاربعاء",
        Thuresday: "الخميس",
        Friday: "الجمعة",
        today: 'اليوم',
        Services: "Services",
        Insurances: "التأمينات",
        Amenities: "الخدمات",
        showLess: "عرض اقل",
        "Link Copied to your clipboard!": "تم نسخ الرابط إلى الحافظة الخاصة بك!",
        recommend: "رشح",
        recommends: "ترشيح",
        Bio: "السيرة الذاتية",
        general: "معلومات الطبيب",
        "language spoken": "اللغات التي يتحدث بها",
        Nationality: 'الجنسية',
        Gender: "الجنس",
        Male: "ذكر",
        Female: "انثى",
        "Can help with": "يمكنه المساعدة في",
        "Clinics Locations": "أماكن العيادات",
        "Facility Name": "اسم المنشأة",
        "Address": "العنوان",
        "Doctor Consultation Fees": "رسوم الاستشارة",
        Professional: "الخبرة",
        Experince: "الخبرة العملية",
        Fellowship: "الزمالة الدراسية",
        Residency: "فترة التدريب",
        "Medical School": "كلية الطب",
        Specialty: "التخصص",
        "Top Specialties": "الاكثر طلبا",
        doctor: "طبيب",
        facility: "منشأة",
        specialty: "تخصص",
        "My insurance": "مغطى بتاميني",
        Languages: "اللغة",
        Reset: "الغاء",
        OK: "تم",
        Profile: "الملف الشخصي",
        Information: "معلومات",
        Insurance: "التأمين الصحي",
        Vouchers: "قسائم",
        Feedback: "تعليقات",
        "No Feedbacks yet!": "لا توجد تعليقات!",
        Back: "رجوع",
        Hi: "مرحبا",
        bookingConfirmed: "مواعيد مؤكدة",
        Favourite: "مفضل",
        Payment: "بطاقات الدفع",
        Language: "اللغة/Language",
        Logout: "تسجيل الخروج",
        "First Name": "الإسم الأول",
        "Last Name": "الكنية",
        "Contact Number": "رقم الاتصال",
        "Email Address": "البريد الإلكتروني",
        "Date Of Birth": "تاريخ الميلاد",
        "+Add Insurance": "+إضافة تأمين",
        "Personal ID": "بطاقة الهوية",
        "+Add your ID": "+إضافة بطاقة هوية",
        "Add Insurance": "إضافة تأمين",
        "Insurance Company*": "شركة تأمين *",
        "Insurance Plan*": "خطة التأمين*",
        "Policy Number*": "رقم بوليصة التأمين",
        "Expiration Date*": "تاريخ الانتهاء*",
        "Upload insurance Card": "تحميل بطاقة التأمين",
        "*upload insurance card with good quality": "*قم بتحميل  بطاقة التأمين بجودة عالية",
        "Upload Insurance card [Front]": "تحميل بطاقة التأمين (أمام)",
        "Upload Insurance card [Back]": "تحميل بطاقة التأمين (خلف)",
        "Edit": "تعديل",
        Cancel: "إلغاء",
        Save: "حفظ",
        "Add ID": "إضافة بطاقة هوية",
        "Upload Personal ID Card": "تحميل بطاقة الهوية",
        "*upload Personal card with good quality": "*قم بتحميل  بطاقة الهوية بجودة عالية",
        "Upload Personal card [Front]": "تحميل بطاقة الهوية (أمام)",
        "Upload Personal card [Back]": "تحميل بطاقة الهوية (خلف)",
        "This voucher is already redeemed!": "هذه القسيمة مستعملة بالفعل!",
        "Your voucher has been redeemed": "تم استخدام قيمة القسيمة",
        "Redeemed": "مستخدم",
        "Voucher is successfuly added to your profile": "تمت إضافة القسيمة بنجاح إلى ملفك الشخصي",
        "This voucher expired!": "انتهت صلاحية هذه القسيمة!",
        "Please enter valid Promo code!": "الرجاء إدخال رمز ترويجي صالح!",
        Get: "إستخدم",
        "Promo code here": "الرمز الترويجي هنا",
        Reviews: "التعليقات",
        "Write a review": "كتابة تعليق",
        Confirm: "تأكيد",
        Alert: "تحذير",
        "No saved cards found": "لا توجد بطاقات مسجلة",
        "Active": "مفعّل",
        "Previous": "سابق",
        "Canceled": "ملغي",
        Clinic: "العيادة",
        Patient: "المريض",
        Service: "الخدمة",
        Date: "تاريخ الحجز",
        Status: "حالة الحجز",
        "New": "جديد",
        "Confirmed": "تم تأكيد",
        "Pending": "قيد الانتظار",
        "Arrived": "تأكد الوصول",
        Done: "إكتمل",
        Join: "إنضم",
        "waiting for doctor...": "في انتظار الطبيب",
        Reschedule: "تغيير الموعد",
        "Book again": "احجز مرة أخرى",
        "Tap Stars to review Doctor": "تقييم الطبيب",
        "No Appointments Found!": "لا توجد مواعيد!",
        "Find your doctor and book your appointment easily!": "ابحث عن طبيبك وإحجز موعدك بسهولة!",
        "Search": "بحث",
        "Are you sure you want to cancel the appointment with Dr.": "هل أنت متأكد من أنك تريد إلغاء الموعد مع",
        "You have requested an appointment with Dr.": "لقد طلبت موعدًا مع ",
        ", Are you sure?": "، هل أنت واثق؟",
        "Don't Cancel": "تجاهل",
        "Select Facility": "إختار المنشأة",
        'Select Service': 'إختر الخدمة',
        'Select Date': 'إختر الموعد',
        'For whom': 'لمن الزيارة',
        'Payment Option': 'طريقة الدفع',
        'CheckOut': 'الدفع',
        Next: "التالي",
        'Proceed to payment': "الشروع في دفع",
        "Reason of the visit": "سبب الزيارة",
        "Final confirmation will be from clinic side": "التأكيد النهائي سيكون من جانب العيادة",
        "No slots available": "لا توجد مواعيد متاحة",
        "Morning": "صباحا ☀️",
        "Afternoon": "ظهرا 🌝",
        "Evening": "مساءا 🌑",
        "Full Name": "الاسم",
        Cash: "الدفع نقدا",
        Card: "الدفع الالكتروني",
        Provider: "مقدم الخدمة",
        Providers: "مقدمي الخدمة",
        Facility: "المنشأة",
        "Date and Time": "موعد الحجز",
        "Booking For": "معلومات الحجز",
        Change: "تغيير",
        "Total Amount": "إجمالي المبلغ",
        "Select Card": "إختر بطاقة الدفع",
        "Use New Card": "بطاقة جديدة",
        "Payment Method": "طريقة الدفع",
        "You have rescheduled an appointment with Dr.": "لقد قمت بإعادة تحديد موعد مع د.",
        "Use New Card": "إستخدام بطاقة جديدة",
        "Add New Insurance": "إضافة تأمين جديد",
        "You have an active request with ": "لديك بالفعل طلب حجز مع ",
        delete: "حذف",
        "Company Name": "إسم الشركة",
        "Staff Number": "رقم الموضف",
        "Company Name": "Company Name",
        "Staff Number": "Staff Number",
        "Payment Cards": "بطاقات الدفع",
        "Select Doctor": "إختر الطبيب",
        "You have to login to start using this feature": "يرجى تسجيل الدخول للبدء باستخدام هذه الخاصية",
        login: "تسجيل الدخول",
        verify: "تحقق",
        verifyCode: "التحقق من الرمز",
        "Continue as a guest": "الاستمرار بدون تسجيل",
        "one last step to upgrade your experince": "خطوة اخيرة لبدء الإستمتاع بالتجربة ",
        "Full Name": "الاسم",
        submit: "إستمر",
        "Doctor is waiting for you": 'الطبيب في انتظارك',


        Nationalities: "الجنسية",
        "Austria": "النمسا",
        "Australia": "أستراليا",
        "Aruba": "أروبا",
        "Argentina": "الأرجنتين",
        "Antigua and Barbuda": "أنتيغوا وبربودا",
        "Angola": "أنغولا",
        "Andorra": "أندورا",
        "Algeria": "الجزائر",
        "Afghanistan": "أفغانستان",
        "Albania": "ألبانيا",
        "Burma": "بورما",
        "BurkinaFaso": "بوركينا فاسو",
        "Bulgaria": "بلغاريا",
        "Brazil": "البرازيل",
        "Botswana": "بوتسوانا",
        "Bosnia and Herzegovina": "البوسنة والهرسك",
        "Bolivia": "بوليفيا",
        "Bhutan": "بوتان",
        "Benin": "بنين",
        "Belize": "بليز",
        "Belgium": "بلجيكا",
        "Belarus": "روسيا البيضاء",
        "Barbados": "بربادوس",
        "Bangladesh": "بنغلاديش",
        "Bahrain": "البحرين",
        "Bahamas": "الباهاما",
        "Czechia": "التشيك",
        "Cyprus": "قبرص",
        "Curacao": "كوراكاو",
        "Cuba": "كوبا",
        "Croatia": "كرواتيا",
        "Cote d'Ivoire": "كوت ديفوار",
        "Costa Rica": "الكوستاريكية",
        "Congo": "الكونغو",
        "Comoros": "جزر القمر",
        "Colombia": "كولومبيا",
        "China": "الصين",
        "Chile": "تشيلي",
        "Chad": "تشاد",
        "Central African Republic": "جمهورية افريقيا الوسطى",
        "Cabo Verde": "كابو فيردي",
        "Canada": "كندا",
        "Cameroon": "الكاميرون",
        "Cambodia": "كمبوديا",
        "Denmark": "الدنمارك",
        "Djibouti": "جيبوتي",
        "Dominica": "دومينيكا",
        "Dominican Republic": "جمهورية الدومنيكان",
        "Ecuador": "الإكوادور",
        "Egypt": "مصر",
        "ElSalvador": "السلفادور",
        "Equatorial Guinea": "غينيا الإستوائية",
        "Eritrea": "إريتريا",
        "Estonia": "استونيا",
        "Ethiopia": "أثيوبيا",
        "Fiji": "فيجي",
        "Finland": "فنلندا",
        "France": "فرنسا",
        "Gabon": "الغابون",
        "Gambia": "غامبيا",
        "Georgia": "جورجيا",
        "Germany": "ألمانيا",
        "Ghana": "غانا",
        "Greece": "اليونان",
        "Grenada": "غرينادا",
        "Guatemala": "غواتيمالا",
        "Guinea": "غينيا",
        "Guinea-Bissau": "غينيا بيساو",
        "Guyana": "غيانا",
        "Haiti": "هايتي",
        "HolySee": "HolySee",
        "Honduras": "هندوراس",
        "Hong Kong": "هونغ كونغ",
        "Hungary": "اليونان",
        "Iceland": "أيسلندا",
        "India": "الهند",
        "Indonesia": "أندونيسيا",
        "Iran": "إيران",
        "Iraq": "العراق",
        "Ireland": "أيرلندا",
        "Italy": "إيطاليا",
        "Palestine": "فلسطين",
        "Jamaica": "جامايكا",
        "Japan": "اليابان",
        "Jordan": "الأردن",
        "Kazakhstan": "كازاخستان",
        "Kenya": "كينيا",
        "Kiribati": "كيريباس",
        "Kosovo": "كوسوفو",
        "Kuwait": "الكويت",
        "Kyrgyzstan": "قرغيزستان",
        "Laos": "لاوس",
        "Latvia": "لاتفيا",
        "Lebanon": "لبنان",
        "Lesotho": "ليسوتو",
        "Liberia": "ليبيريا",
        "Libya": "ليبيا",
        "Liechtenstein": "ليختنشتاين",
        "Lithuania": "ليتوانيا",
        "Luxembourg": "لوكسمبورغ",
        "Macau": "ماكاو",
        "Macedonia": "مقدونيا",
        "Madagascar": "مدغشقر",
        "Malawi": "مالاوي",
        "Malaysia": "ماليزيا",
        "Maldives": "جزر المالديف",
        "Mali": "مالي",
        "Malta": "مالطا",
        "Marshall Islands": "جزر مارشال",
        "Mauritania": "موريتانيا",
        "Mauritius": "موريشيوس",
        "Mexico": "المكسيك",
        "Micronesia": "ميكرونيزيا",
        "Moldova": "مولدوفا",
        "Monaco": "موناكو",
        "Mongolia": "منغوليا",
        "Montenegro": "الجبل الأسود",
        "Morocco": "المغرب",
        "Mozambique": "موزمبيق",
        "Namibia": "ناميبيا",
        "Nauru": "ناورو",
        "Nepal": "نيبال",
        "Netherlands": "هولندا",
        "New Zealand": "نيوزيلاندا",
        "Nicaragua": "نيكاراغوا",
        "Niger": "النيجر",
        "Nigeria": "نيجيريا",
        "North Korea": "كوريا الشمالية",
        "Norway": "النرويج",
        "Oman": "سلطنة عمان",
        "Pakistan": "باكستان",
        "Palau": "بالاو",
        "Panama": "بناما",
        "Papua New Guinea": "بابوا غينيا الجديدة",
        "Paraguay": "باراغواي",
        "Peru": "بيرو",
        "Philippines": "الفلبين",
        "Poland": "بولندا",
        "Portugal": "البرتغال",
        "Qatar": "قطر",
        "Romania": "رومانيا",
        "Russia": "روسيا",
        "Rwanda": "رواندا",
        "Saint Kitts and Nevis": "سانت كيتس ونيفيس",
        "Saint Lucia": "SaintLucia",
        "Saint Vincent and the Grenadines": "سانت فنسنت وجزر غرينادين",
        "Samoa": "ساموا",
        "San Marino": "سان مارينو",
        "Siloni": "سيلوني",
        "Azerbaijan": "أذربيجان",
        "Sao Tome and Principe": "ساو تومي وبرنسيبي",
        "Saudi Arabia": "المملكة العربية السعودية",
        "Senegal": "السنغال",
        "Serbia": "صربيا",
        "Seychelles": "سيشيل",
        "Sierra Leone": "سيراليون",
        "Singapore": "سنغافورة",
        "Sint Maarten": "سينت مارتن",
        "Slovakia": "سلوفاكيا",
        "Slovenia": "سلوفينيا",
        "Solomon Islands": "جزر سليمان",
        "Somalia": "الصومال",
        "South Africa": "جنوب أفريقيا",
        "South Korea": "كوريا الجنوبية",
        "South Sudan": "جنوب السودان",
        "Spain": "إسبانيا",
        "SriLanka": "سيريلانكا",
        "Sudan": "سودان",
        "Suriname": "سورينام",
        "Swaziland": "سوازيلاند",
        "Sweden": "السويد",
        "Switzerland": "سويسرا",
        "Syria": "سوريا",
        "Taiwan": "تايوان",
        "Tajikistan": "طاجيكستان",
        "Tanzania": "تنزانيا",
        "Thailand": "تايلاند",
        "Timor-Leste": "تيمور الشرقية",
        "Togo": "Togo",
        "Tonga": "تونغا",
        "Trinidad and Tobago": "ترينداد وتوباغو",
        "Tunisia": "تونس",
        "Turkey": "تركيا",
        "Turkmenistan": "تركمانستان",
        "Tuvalu": "توفالو",
        "Uganda": "أوغندا",
        "Ukraine": "أوكرانيا",
        "United Arab Emirates": "الإمارات العربية المتحدة",
        "United Kingdom": "المملكة المتحدة",
        "United States": "الولايات المتحدة",
        "Uruguay": "أوروغواي",
        "Uzbekistan": "أوزبكستان",
        "Vanuatu": "فانواتو",
        "Venezuela": "فنزويلا",
        "Vietnam": "فيتنام",
        "Yemen": "اليمن",
        "Zambia": "زامبيا",
        "Zimbabwe": "زيمبابوي",
        "Armenia": "أرمينيا",

        //languages
        "German": "الألمانية",
        "English": "الإنجليزية",
        "French": "الفرنسية",
        "Arabic": "العربية",
        "Persian (Farsi)": "الفارسية",
        "Urdu": "الأردية",
        "Greek": "اليونانية",
        "Turkish": "التركية",
        "Portuguese": "البرتغالية",
        "Hindi": "الهندية",
        "Spanish": "الأسبانية",
        "Russian": "الروسية",
        "Kashmiri": "كشمير",
        "Kannada": "الكانادا",
        "Marathi": "المهاراتية",
        "Malay": "الملايو",
        "Tamil": "التاميل",
        "Gujarati": "الغوجاراتية",
        "Punjabi": "البنجابية",
        "Romanian": "الرومانية",
        "Italian": "الإيطالية",
        "Swedish": "السويدية",
        "Sindhi": "السندية",
        "Bengali": "البنغالي",
        "Telugu": "التيلجو",
        "Kiswahili(Swahili)": "السواحيلية",
        "Afrikaans": "الأفريكانية",
        "Slovak": "السلوفاكية",
        "Czech": "التشيكوسلوفاكية",
        "Korean": "الكورية",
        "Malayalam": "المالايالامية",
        "Tagalog": "التغالوغ",
        "Konkani": "الكونكانية",
        "Dutch": "الهولندية",
        "Yoruba": "اليوروبا",
        "Polish": "البولندية",
        "Japanese": "اليابانية",
        "Chinese": "الصينية",
        "Pakistani": "الباكستانية",
        "Danish": "الدانمركية",
        "Farsi": "الفارسية",
        "Indian": "الهندية",
        "Gujrati": "جوجراتي",
        "Philipino": "الفيليبنية",
        "Armenian": "الأرميني",
        "Finnish": "الفنلندية",
        "Croatian": "الكرواتية",
        "Persian": "الفارسية",
        "Norwegian": "النرويجية",
        "Hindu": "الهندوسي",
        "Pashto": "الباشتو",
        "Maltese": "المالطية",
        "Scandinavian": "الاسكندنافية",
        "Hungarian": "الهنغارية",
        "Catalan": "الكاتالونية",
        "Malaysian": "الماليزية",
        "Swiss": "السويسرية",
        "Panjabi": "بنجابي",
        "Bulgarian": "البلغارية",
        "Vietnamese": 'فيتنامي',








    }
})

export default Strings