
import React from 'react';
import { getName } from '../../helpers';
import Strings from '../../strings';

const about = (props) => {
    return (
        <div className='section'>
            <div className='sectionHead'>
                {
                    Strings.about
                }
                
        </div>
            <div className='sectionDesc'>
            {getName(props.facility,"description")}
            </div>
        </div>
    );
};

export default about;