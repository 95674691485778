import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Input, Modal, Checkbox, Loader, Dimmer } from 'semantic-ui-react'
import { auth, firestore } from '../fire'
import './bookingScreen.css'
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/mobile.css"

import Strings from '../strings';
import { getName } from '../helpers';
import Insurance from '../profile/insurence'
import { withRouter } from 'react-router-dom'

function optimizeDate(date) {
    if (date) {
        let optimized = date.toLowerCase().replace(':am', ':AM').replace(':pm', ':PM').replace(' am', ':AM').replace(' pm', ':PM')
        let spliteed = optimized.split(":")
        return { hours: (((spliteed[2] === 'PM') && (spliteed[0] !== '12')) ? (parseInt(spliteed[0]) + 12) : parseInt(spliteed[0])), min: parseInt(spliteed[1]), flag: spliteed[2] }
    }

}
function shift(date) {
    var currentHour = date.format("HH");
    if (currentHour >= 0 && currentHour < 12) {
        return "Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
        return "Afternoon";
    } else if (currentHour >= 17 && currentHour < 24) {
        return "Evening";
    }
}
function translateSpec(key, specs) {
    if (specs) {
        let selectedSpec = specs.find(rec => rec.id === key)
        return selectedSpec
    }
    return {}

}
class index extends Component {
    state = {
        step: (new URL(document.location)).searchParams.has('selectDoctor') ? 'selectDoctor' : 0,
        selectedDate: moment(),
        clinics: [],
        activeServices: [],
        rescadual: (new URL(document.location)).searchParams.get('rescadual'),
        doctorId: (new URL(document.location)).searchParams.get('doctor_id'),
        facilityId: (new URL(document.location)).searchParams.get('facility_id'),

    }
    componentDidMount() {
        let { rescadual, doctorId, facilityId, step } = this.state
        let { user, history } = this.props
        let { userCards } = user
        if (!user) {
            history.replace('/login')
        }

        let activeBooking;

        if (rescadual) {
            activeBooking = user?.userBookings?.find(rec => rec.id === rescadual)
        }
        if (doctorId) {
            let selectedDoctor = this.props.providers?.providers?.find(rec => rec.id === doctorId)
            let { bookingActive } = selectedDoctor
            if (bookingActive) {
                let activeClinics = Object.keys(selectedDoctor.clinics)
                let clinics = Object.keys(bookingActive).map(key => {
                    if (activeClinics.includes(key)) {
                        let clinicObject = this.props.providers?.facilities.find(rec => rec?.facilityId === key)
                        if (clinicObject && clinicObject?.bookingActive) {
                            return clinicObject
                        }
                    }
                })
                clinics = clinics.filter((x) => x !== undefined);
                let selectedClinic;
                if (rescadual) {
                    selectedClinic = clinics?.find(rec => rec?.facilityId === activeBooking.facilityId)
                    this.getActiveServices(selectedDoctor, activeBooking?.facilityId, activeBooking)
                } else if (facilityId) {
                    selectedClinic = clinics?.find(rec => rec?.facilityId === facilityId)
                    this.getActiveServices(selectedDoctor, facilityId)
                }
                this.setState({
                    selectedDoctor,
                    step: selectedClinic ? 1 : 0,
                    selectedClinic,
                    clinics: (clinics && clinics.filter(rec => !!rec)),
                    rescadual: rescadual, activeBooking,
                    selectedCard: userCards ? { ...userCards[Object.keys(userCards)[0]], id: Object.keys(userCards)[0] } : false
                })
            }

        }
        if (step === 'selectDoctor') {
            let facilityId = (new URL(document.location)).searchParams.get('selectDoctor')
            let doctors = this.props.providers?.providers?.filter(rec => rec?.bookingActive && rec?.bookingActive[facilityId])
            let selectedClinic = this.props.providers?.facilities.find(rec => rec?.facilityId === facilityId)
            this.setState({ activeDoctors: doctors, selectedClinic })
        }

    }
    bookAppointment() {
        let { user } = this.props
        let { selectedDoctor,
            selectedClinic,
            selectedPatient,
            selectedService,
            selectedSlot,
            selectedDate,
            rescadual,
            activeBooking,
            paymentType,
            selectedInsurance,
            selectedCard } = this.state

        let splittedSlot = optimizeDate(selectedSlot)

        let dateTS = selectedDate.set({ hour: splittedSlot.hours, minute: splittedSlot.min })

        let optimizedInsurance = {}
        if (selectedInsurance) {
            optimizedInsurance = { ...selectedInsurance, id: selectedInsurance?.insurance, insurance: selectedInsurance?.insuranceName }
            delete optimizedInsurance?.insuranceName;
            delete optimizedInsurance?.insuranceName_ar;
        }
        let post = {
            created: Date.now(),
            date: selectedDate.format('MMM DD, yyyy') + ' at ' + selectedSlot,
            dateTS: dateTS.valueOf(),
            doctor: selectedDoctor.id,
            facilityAdmin: selectedClinic?.user?.superAdmin || '',
            facilityId: selectedClinic.facilityId,
            facilityName: selectedClinic.facilityName,
            insurance: selectedInsurance ? optimizedInsurance : '',
            patient: user.uid,
            patientName: [user.firstName, user.lastName].join(' '),
            service: selectedService.id,
            status: 'new',
            isArchived: false,
            amount: selectedService?.fees || 0,
            ln: 'en',
            paymentType: paymentType
        }
        if (!rescadual && (selectedPatient.id !== user.uid)) {
            post.refered = selectedPatient.id
            post.patientName = selectedPatient.name
            if (selectedPatient.new) {
                delete selectedPatient.new;
                firestore().collection('users').doc(auth().currentUser.uid).update({
                    [`otherPatients.${selectedPatient.id}`]: selectedPatient
                })
            }
        }

        let reservedSlots = []
        let start = moment().set({ hour: optimizeDate(selectedSlot).hours, minute: optimizeDate(selectedSlot).min })
        let end = moment().set({ hour: optimizeDate(selectedSlot).hours, minute: (optimizeDate(selectedSlot).min + (15)) })

        //duration related to service
        // let end = moment().set({ hour: optimizeDate(selectedSlot).hours, minute: (optimizeDate(selectedSlot).min + (selectedService.duration || 15)) })

        while (start < end) {
            reservedSlots.push(start.format('hh:mm A'))
            start.add('15', 'minute')
        }
        if (rescadual) {
            firestore().collection('bookings').doc(rescadual).update({
                status: 'new',
                rescadual: Date.now(),
                date: selectedDate.format('MMM DD, yyyy') + ' at ' + selectedSlot,
                dateTS: dateTS.valueOf(),
            }).then(() => {
                let oldDateTS = moment(activeBooking?.dateTS)
                let oldStart = moment(oldDateTS)
                let oldEnd = moment(oldDateTS).add(activeBooking?.duration || 15, 'minute')
                let deletionSlots = []
                while (oldStart < oldEnd) {
                    deletionSlots.push(oldStart.format('hh:mm A'))
                    oldStart.add('15', 'minute')
                }
                firestore().collection('slots').doc(selectedDoctor.id).collection(selectedClinic.facilityId).doc(oldDateTS.format('MMM DD, yyyy')).set({
                    [selectedDate.format('MMM DD, yyyy')]: firestore.FieldValue.arrayRemove(...deletionSlots)
                }, { merge: true }).then(() => {
                    firestore().collection('slots').doc(selectedDoctor.id).collection(selectedClinic.facilityId).doc(selectedDate.format('MMM DD, yyyy')).set({
                        [selectedDate.format('MMM DD, yyyy')]: firestore.FieldValue.arrayUnion(...reservedSlots)
                    }, { merge: true }).then(() => {
                        this.props.history.push("/appointments");
                    })
                })

            })
        } else {
            let ref = firestore().collection(paymentType === 'Card' ? 'authorizing' : 'bookings')
            let bookingId = ref.doc().id
            if (paymentType === 'Card') {
                post.authRequest = {
                    "currency": "OMR",
                    "amount": selectedService?.fees,
                    "threeDSecure": true,
                    "save_card": true,
                    "metadata": {
                        "bookingId": bookingId
                    }
                }
            }

            ref.doc(bookingId).set(post).then(() => {
                firestore().collection('slots').doc(selectedDoctor.id).collection(selectedClinic.facilityId).doc(selectedDate.format('MMM DD, yyyy')).set({
                    [selectedDate.format('MMM DD, yyyy')]: firestore.FieldValue.arrayUnion(...reservedSlots)
                }, { merge: true }).then(() => {
                    if (paymentType === 'Card') {
                        if (selectedCard) {
                            this.props.history.push(`/pay?booking_id=${bookingId}&user=${user.uid}&card=${selectedCard?.id}`);
                        } else {
                            this.props.history.push(`/pay?booking_id=${bookingId}&user=${user.uid}`);

                        }
                    } else {
                        this.props.history.push("/appointments");
                    }
                })
            })
        }

    }
    getService = async (key) => {
        let data = await firestore().collection('services').doc(key).get();
        return data.data()

    }
    getActiveServices(item, facilityId, activeBooking) {
        let { services } = this.props.providers
        let { selectedClinic, rescadual } = this.state
        let activeServices = []
        let count = 0
        firestore().collection('doctors').doc(item.id).collection('services').doc(facilityId || selectedClinic?.facilityId).get().then(res => {
            if (res.data()) {
                Object.keys(res.data()).map(async record => {
                    let data = res.data()[record];
                    let service = services?.find(rec => rec.id === record.id)
                    if (service) {
                        count++
                        activeServices.push({ ...data, name: service?.name, name_ar: service?.name_ar, id: record })
                    } else if (record === 'inpersonConsult') {
                        count++
                        activeServices.push({ ...data, name: 'In Clinic Consultation', name_ar: "استشارة في العيادة", id: record })
                    } else if (record === 'onlineConsult') {
                        count++
                        activeServices.push({ ...data, name: 'Online Consultation', name_ar: "استشارة اونلاين", id: record })
                    } else {
                        this.getService(record).then(dataSnap => {
                            count++
                            activeServices.push({ ...data, name: dataSnap.name, name_ar: dataSnap?.name_ar, id: record })
                            if (count === Object.keys(res.data()).length) {
                                this.setState({ activeServices, loading: false }, () => {
                                    if (rescadual && activeBooking?.service) {
                                        let selectedService = activeServices.find(rec => rec.id === activeBooking?.service)
                                        if (selectedService) {
                                            this.setState({ selectedService, step: 2 })
                                        }
                                    }
                                })
                            }

                        })
                    }
                })

                if (count === Object.keys(res.data()).length) {
                    this.setState({ activeServices, loading: false }, () => {
                        if (rescadual && activeBooking?.service) {
                            let selectedService = activeServices.find(rec => rec.id === activeBooking?.service)
                            if (selectedService) {
                                this.setState({ selectedService, step: 2 })
                            }
                        }
                    })

                }

            } else {
                let doctorServices = item.services
                if (doctorServices) {

                    Object.keys(doctorServices).map(key => {
                        let service = services?.find(rec => rec.id === key)
                        if (service) {
                            activeServices.push({
                                fees: doctorServices[service.id],
                                name: service.name,
                                id: key,
                                doctorTimings: item.doctorTimings[facilityId || selectedClinic?.facilityId],
                                duration: item.duration[facilityId || selectedClinic?.facilityId]
                            })
                        }
                        count++
                    })
                    if (Object.keys(doctorServices).length === count) {
                        this.setState({ activeServices, loading: false }, () => {
                            if (rescadual && activeBooking?.service) {
                                let selectedService = activeServices.find(rec => rec.id === activeBooking?.service)
                                if (selectedService) {
                                    this.setState({ selectedService, step: 2 })
                                }
                            }
                        })
                    }
                } else {
                    this.setState({ activeServices, loading: false })
                }
            }

        })
    }
    getActiveSlots = async () => {
        let { selectedService, selectedDate, selectedDoctor, selectedClinic } = this.state
        let { doctorTimings } = selectedService
        let weekDay = (selectedDate.format('ddd')).toUpperCase()
        let selectedDateTiming = doctorTimings[weekDay]
        let slots = {}
        if (selectedDateTiming) {
            let { opened, isTwentyFour, isSplitShift, fromTime, toTime, pmFromTime, pmToTime } = selectedDateTiming
            if (opened) {
                let reservedSlots = await firestore().collection('slots').doc(selectedDoctor.id).collection(selectedClinic.facilityId).doc(selectedDate.format('MMM DD, yyyy')).get();
                let reservedSlotsArray = reservedSlots.exists ? reservedSlots.data()[selectedDate.format('MMM DD, yyyy')] : []

                let optimizedFrom = selectedDate.clone().set({ hour: optimizeDate(fromTime).hours, minute: optimizeDate(fromTime).min })
                let optimizedTo = selectedDate.clone().set({ hour: optimizeDate(toTime).hours, minute: optimizeDate(toTime).min })

                if (isSplitShift) {
                    let optimizedPMFrom = selectedDate.clone().set({ hour: optimizeDate(pmFromTime).hours, minutes: optimizeDate(pmFromTime).min })
                    let optimizedPMTo = selectedDate.clone().set({ hour: optimizeDate(pmToTime).hours, minutes: optimizeDate(pmToTime).min })
                    while (optimizedFrom < optimizedTo) {
                        if (!reservedSlotsArray.includes(optimizedFrom.format('hh:mm A')) && (optimizedFrom > moment())) {
                            if (slots[shift(optimizedFrom)]) {
                                slots[shift(optimizedFrom)].push(optimizedFrom.format('hh:mm A'))
                            } else {
                                slots[shift(optimizedFrom)] = [optimizedFrom.format('hh:mm A')]
                            }
                        }
                        optimizedFrom.add(selectedService.duration, 'minutes')
                    }
                    while (optimizedPMFrom < optimizedPMTo) {
                        if (!reservedSlotsArray.includes(optimizedPMFrom.format('hh:mm A')) && (optimizedPMFrom > moment())) {
                            if (slots[shift(optimizedPMFrom)]) {
                                slots[shift(optimizedPMFrom)].push(optimizedPMFrom.format('hh:mm A'))
                            } else {
                                slots[shift(optimizedPMFrom)] = [optimizedPMFrom.format('hh:mm A')]
                            }
                        }

                        optimizedPMFrom.add(selectedService.duration, 'minutes')
                    }
                } else {
                    while (optimizedFrom < optimizedTo) {
                        if (!reservedSlotsArray.includes(optimizedFrom.format('hh:mm A')) && (optimizedFrom > moment())) {
                            if (slots[shift(optimizedFrom)]) {
                                slots[shift(optimizedFrom)].push(optimizedFrom.format('hh:mm A'))
                            } else {
                                slots[shift(optimizedFrom)] = [optimizedFrom.format('hh:mm A')]
                            }
                        }
                        optimizedFrom.add(selectedService.duration, 'minutes')
                    }
                }
            }
            this.setState({ slots, loading: false })
        } else {
            this.setState({ slots, loading: false })

        }

    }
    renderSelectDoctor() {
        let { activeDoctors, selectedClinic } = this.state
        let { providers } = this.props
        let lang = localStorage.getItem('lang')
        return (
            <div style={{ padding: '8px 0' }}>
                {
                    activeDoctors?.map(item => {
                        let selectedSpecilatykey = item?.clinics[selectedClinic?.facilityId];
                        let selectedSpecilaty = selectedSpecilatykey?.length ? translateSpec(selectedSpecilatykey[0], providers?.specalities) : {}

                        return (
                            <div key={item.id}
                                onClick={() => {
                                    this.getActiveServices(item, selectedClinic?.facilityId)
                                    this.setState({ step: 1, selectedDoctor: item })
                                }}
                                style={{
                                    backgroundColor: '#cacaca30',
                                    margin: '8px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    cursor: 'pointer',
                                    border: '1px solid #cacaca30'
                                }}>
                                <img src={item?.profilePicture} style={{ width: '58px', height: '50px', borderRadius: '8px', margin: '8px', objectFit: 'cover' }} />
                                <div style={{ padding: '8px', textAlign: 'initial', width: '100%' }}>
                                    <div style={{ borderBottom: '1px solid #cacaca', paddingBottom: '8px' }}>
                                        <div style={{ fontFamily: 'tfMedium', display: 'flex', justifyContent: 'space-between' }}>
                                            <div>{getName(item, "name")}</div>
                                            <Icon name={lang === 'ar' ? 'angle left' : 'angle right'} style={{ color: 'var(--themeColor)', fontSize: '18px' }} />
                                        </div>
                                        <div style={{ color: '#cacaca' }}>{getName(selectedSpecilaty, "name")}</div>
                                    </div>
                                    <div style={{ paddingTop: '8px' }}>
                                        {
                                            [getName(selectedClinic, "facilityName"), getName(selectedClinic, "address")?.area, getName(selectedClinic, "address")?.city].join(' - ')
                                        }
                                    </div>
                                </div>
                            </div >
                        )
                    })
                }
            </div>
        )
    }
    render() {
        let lang = localStorage.getItem('lang')
        let { user, providers } = this.props
        if (!user) {
            return ''
        }
        let { addNewInsu, loading, step, selectedPatient, confirmPopup, rescadual, paymentType, addNewPatient, selectCard, selectedInsurance } = this.state

        if (addNewInsu) {
            return (
                <Insurance newInsurance={true} user={user} insurnces={providers?.insurnces} dismiss={() => this.setState({ addNewInsu: false })} />
            )
        }
        let selectDoctor = (new URL(document.location)).searchParams.has('selectDoctor')
        return (
            <div>
                {
                    loading &&
                    <Loader active />
                }
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    <div style={{ color: '#fff', width: '50px' }} onClick={() =>
                        rescadual || (step === 'selectDoctor') || (selectDoctor && step === 1) ?
                            window.history.back()
                            :
                            step !== 0 ?
                                this.setState({ step: step - 1, paymentType: step === 4 ? false : paymentType })
                                :
                                window.history.back()}>
                        <Icon style={{ fontSize: '22px' }} name={lang === 'ar' ? 'arrow right' : 'arrow left'}></Icon>
                    </div>
                    <div style={{ color: '#fff', fontFamily: 'tfBold' }}>
                        {
                            step === 'selectDoctor' ?
                                Strings['Select Doctor']
                                : step === 0 ?
                                    Strings['Select Facility']
                                    : step === 1 ?
                                        Strings['Select Service']
                                        : step === 2 ?
                                            Strings['Select Date']
                                            : step === 3 ?
                                                Strings['For whom']
                                                : step === 4 ?
                                                    Strings['Payment Option']
                                                    : step === 5 ?
                                                        Strings.CheckOut
                                                        : ''


                        }

                    </div>
                    <div style={{ width: '50px' }} />
                </div>
                {
                    step === 'selectDoctor' ?
                        this.renderSelectDoctor()
                        : step === 0 ?
                            this.renderSelectFacility()
                            : step === 1 ?
                                this.renderSelectService()
                                : step === 2 ?
                                    this.renderDateSelect()
                                    : step === 3 ?
                                        this.renderSelectPatient()
                                        : step === 4 ?
                                            this.renderPaymentSelection()
                                            : step === 5 ?
                                                this.renderCheckout()
                                                : ''
                }
                {
                    step >= 3 && selectedPatient ?
                        <div
                            onClick={() => {
                                let allow = paymentType && ((paymentType === 'Cash') || (paymentType === 'Card') || (paymentType === 'Insurance' && selectedInsurance))
                                this.setState({ step: (allow ? 5 : 4), confirmPopup: (allow && step === 5 ? true : false) })
                            }}
                            style={{
                                position: 'sticky',
                                bottom: '0px',
                                width: '100%',
                                backgroundColor: '#FE685C',
                                color: '#fff',
                                padding: '29px',
                                fontSize: '20px',
                                fontFamily: 'tfBold'
                            }}>
                            {
                                step === 3 ?
                                    Strings.Next
                                    :
                                    step === 4 ?
                                        Strings.CheckOut
                                        :
                                        paymentType === 'Card' && step === 5 ?
                                            Strings['Proceed to payment']
                                            :
                                            Strings.book
                            }

                        </div>
                        : ''
                }
                {
                    confirmPopup && this.renderConfirmPopup()
                }
                {addNewPatient && this.renderAddNewPatient()}

                {
                    selectCard && this.rencerCardSelection(selectCard)
                }
            </div >
        );
    }
    renderSelectFacility() {
        let { clinics } = this.state
        return (
            <div style={{ padding: '8px 0' }}>
                {
                    clinics.map(item => {
                        return (
                            this.renderCard(item)
                        )
                    })
                }
            </div>
        )
    }
    renderCard(item) {
        let { selectedClinic, step, selectedDoctor } = this.state
        let { providers } = this.props
        let selectedSpecilatykey = selectedDoctor?.clinics[selectedClinic?.facilityId];
        let selectedSpecilaty = selectedSpecilatykey?.length ? translateSpec(selectedSpecilatykey[0], providers?.specalities) : {}
        let lang = localStorage.getItem('lang')
        return (
            <div key={item.facilityId || selectedDoctor.id}
                onClick={() => {
                    if (step === 0) {
                        let specKey = selectedDoctor?.clinics[item.facilityId];
                        let selectedSpecilaty = specKey?.length ? translateSpec(specKey[0], providers?.specalities) : {}
                        this.setState({ step: 1, selectedClinic: item, selectedSpecilaty, loading: true }, () => {
                            this.getActiveServices(selectedDoctor);
                        })
                    }
                }}
                style={{
                    backgroundColor: '#cacaca30',
                    margin: '8px',
                    padding: '8px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                    border: '1px solid #cacaca30'
                }}>
                <img src={step === 0 ? item?.logoUrl : selectedDoctor?.profilePicture} style={{ width: '58px', height: '50px', borderRadius: '8px', margin: '8px', objectFit: 'cover' }} />
                <div style={{ padding: '8px', textAlign: 'initial', width: '100%' }}>
                    <div style={{ borderBottom: '1px solid #cacaca', paddingBottom: '8px' }}>
                        <div style={{ fontFamily: 'tfMedium', display: 'flex', justifyContent: 'space-between' }}>
                            <div>{getName(item, "facilityName") || getName(selectedDoctor, "name")}</div>
                            {
                                step === 0 && <Icon name={lang === 'ar' ? 'angle left' : 'angle right'} style={{ color: 'var(--themeColor)', fontSize: '18px' }} />
                            }
                        </div>
                        <div style={{ color: '#cacaca' }}>{step === 0 ? `Cons Fees :${selectedDoctor?.bookingActive[item?.facilityId]} ${process.env.REACT_APP_CURRNECY}` : getName(selectedSpecilaty, "name")}</div>

                    </div>
                    <div style={{ paddingTop: '8px' }}>
                        {step === 0 ? [getName(item, "address")?.area, getName(item, "address")?.city].join(' - ')
                            :
                            [getName(selectedClinic, "facilityName"), getName(selectedClinic, "address")?.area, getName(selectedClinic, "address")?.city].join(' - ')
                        }
                    </div>
                </div>
            </div >
        )

    }
    renderSelectService() {
        let { selectedDoctor, activeServices } = this.state
        return (
            <div style={{ padding: '8px' }}>
                {this.renderCard(selectedDoctor)}
                <div style={{ fontFamily: 'tfBold', fontSize: '18px', textAlign: 'initial', padding: '16px 8px 8px' }}>
                    {Strings['Reason of the visit']}
                </div>
                {
                    activeServices.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => this.setState({ step: 2, selectedService: item, loading: true }, () => {
                                    this.getActiveSlots()
                                })}
                                style={{
                                    display: 'flex',
                                    padding: '12px',
                                    margin: '12px 16px',
                                    borderRadius: '8px',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#cacaca30'
                                }}>

                                <div style={{ fontFamily: 'tfMedium', color: '#4d4d4d' }}>{getName(item, "name")}</div>
                                <div style={{ color: 'var(--themeColor)', fontFamily: 'tfMedium' }}>{item.fees} {process.env.REACT_APP_CURRNECY}</div>
                            </div>
                        )
                    })
                }
            </div>
        )

    }
    renderDateSelect() {
        let { selectedDate, selectedService, slots } = this.state
        let lang = localStorage.getItem('lang')
        return (
            <div style={{ padding: '8px' }}>
                {this.renderCard(1)}
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', color: '#4d4d4d', alignItems: 'center' }}>
                    <Icon
                        name={lang === 'ar' ? 'arrow right' : 'arrow left'}
                        style={{ fontSize: '16px' }}
                        onClick={() => this.setState({ loading: true, selectedDate: selectedDate > moment() ? selectedDate.add(-1, 'day') : selectedDate }, () => {
                            this.getActiveSlots()
                        })}
                    />
                    <div>
                        <div style={{ fontFamily: 'tfBold', fontSize: '16px', color: '#4d4d4d' }}>{selectedDate.format('ddd DD, MMM')}</div>
                        <div style={{ fontSize: '12px', color: '#cacaca' }}>{Strings['Final confirmation will be from clinic side']}</div>
                    </div>
                    <Icon name={lang === 'ar' ? 'arrow left' : 'arrow right'} style={{ fontSize: '16px' }}
                        onClick={() => this.setState({ loading: true, selectedDate: selectedDate.add(1, 'day') }, () => {
                            this.getActiveSlots()
                        })}
                    />
                </div>
                {
                    slots && Object.keys(slots).length ? Object.keys(slots).map(rec =>
                        <div key={rec} style={{ backgroundColor: '#cacaca30', margin: '8px', borderRadius: '8px', border: '1px solid #cacaca' }}>
                            <div style={{ padding: '12px', color: '#4d4d4d', borderBottom: '1px solid #cacaca', fontFamily: 'tfMedium', textAlign: 'initial' }}>
                                {Strings[rec]}
                            </div>
                            {
                                this.shiftCard(slots[rec])
                            }


                        </div>
                    )
                        :
                        <div style={{
                            paddingTop: '50%'
                        }}>{Strings['No slots available']}</div>
                }
            </div>
        )
    }
    shiftCard(item) {
        let { rescadual } = this.state
        return (
            <div style={{ display: 'flex', flexFlow: 'wrap', padding: '8px' }}>
                {
                    item.map(rec => {
                        return (
                            <div key={rec}
                                onClick={() => {
                                    if (rescadual) {
                                        this.setState({ selectedSlot: rec, confirmPopup: true })
                                    } else {
                                        this.setState({ step: 3, selectedSlot: rec })
                                    }

                                }}
                                style={{
                                    width: 'calc(33% - 8px) ',
                                    padding: '8px 16px',
                                    backgroundColor: '#fff',
                                    borderRadius: '4px',
                                    border: '1px solid var(--themeColor)',
                                    margin: '4px',
                                    color: 'var(--themeColor)',
                                    cursor: 'pointer',
                                    direction: 'ltr',
                                    whiteSpace: 'nowrap'
                                }}>
                                {rec}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    renderSelectPatient() {
        let { user } = this.props
        let { selectedPatient, newPatient } = this.state

        let patients = [
            {
                birthday: user.birthday,
                gender: user.gender,
                id: user.uid,
                name: [user.firstName, user.lastName].join(' ')
            }
        ]
        if (user.otherPatients) {
            patients = patients.concat(Object.keys(user.otherPatients).map(key => {
                let patient = user.otherPatients[key]
                return ({ ...patient, id: key })
            }))
        }
        if (newPatient) {
            patients.push(newPatient)
        } else {
            patients.push({
                id: "else",
                name: 'Someone Else',
                name_ar: 'إضافة شخص آخر'
            })
        }

        return (
            <div style={{ padding: '8px', minHeight: '90vh' }}>
                {this.renderCard(1)}
                <div style={{ fontFamily: 'tfBold', fontSize: '18px', textAlign: 'initial', padding: '16px 8px 8px' }}>
                    {Strings['For whom']}
                </div>
                {
                    patients.map(item => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => item.id !== 'else' ? this.setState({ selectedPatient: item }) : this.setState({ addNewPatient: true })}
                                style={{
                                    display: 'flex',
                                    padding: '12px',
                                    margin: '12px 16px',
                                    borderRadius: '8px',
                                    justifyContent: 'space-between',
                                    backgroundColor: selectedPatient?.id === item?.id ? 'var(--themeColor)' : '#cacaca30',
                                    textAlign: 'initial',
                                    alignItems: 'center',
                                    border: '1px solid #cacaca30',
                                }}>
                                <div style={{ fontFamily: 'tfMedium', color: selectedPatient?.id === item?.id ? '#fff' : '#4d4d4d' }}>
                                    <div style={{ padding: '4px 0' }}>{getName(item, "name")}</div>
                                    <div>{item.birthday}</div>
                                </div>
                                {
                                    item.id !== 'else' && <div
                                        onClick={() => this.setState({
                                            newPatientDate: moment(item.birthday).valueOf(),
                                            newPatientGender: item.gender,
                                            newPatientName: item.name,
                                            addNewPatient: item?.id
                                        })}
                                        style={{
                                            color: selectedPatient?.id === item?.id ? '#fff' : 'var(--themeColor)',
                                            fontFamily: 'tfMedium'
                                        }}>
                                        {
                                            Strings.Edit
                                        }
                                    </div>

                                }
                            </div>
                        )
                    })
                }


            </div>
        )
    }
    submitNewPatient() {
        let { newPatientDate, newPatientName, newPatientGender, addNewPatient } = this.state
        if (!newPatientDate || !newPatientName || !newPatientGender) {
            return alert('Please fill all required fields')
        }
        let id = firestore().collection('users').doc().id
        if (typeof addNewPatient === 'string') {
            id = addNewPatient
            if (id === auth().currentUser.uid) {
                let nameSplited = newPatientName.split(' ')
                firestore().collection('users').doc(id).update({
                    firstName: nameSplited[0],
                    lastName: nameSplited.slice(1).join(' '),
                    birthday: moment(newPatientDate).format('MMM DD, yyyy'),
                    gender: newPatientGender
                }).then(() => {
                    this.setState({
                        selectedPatient: {
                            name: newPatientName,
                            birthday: moment(newPatientDate).format('MMM DD, yyyy'),
                            gender: newPatientGender,
                            id: id
                        },
                        newPatientDate: false,
                        newPatientName: false,
                        newPatientGender: false,
                        addNewPatient: false
                    })
                })
            } else {
                firestore().collection('users').doc(auth().currentUser.uid).update({
                    [`otherPatients.${id}`]: {
                        name: newPatientName,
                        birthday: moment(newPatientDate).format('MMM DD, yyyy'),
                        gender: newPatientGender
                    }
                }).then(() => {
                    this.setState({
                        selectedPatient: {
                            name: newPatientName,
                            birthday: moment(newPatientDate).format('MMM DD, yyyy'),
                            gender: newPatientGender,
                            id: id
                        },
                        newPatientDate: false,
                        newPatientName: false,
                        newPatientGender: false,
                        addNewPatient: false
                    })
                })
            }
        } else {
            let post = {
                name: newPatientName,
                birthday: moment(newPatientDate).format('MMM DD, yyyy'),
                gender: newPatientGender,
                id: firestore().collection('users').doc().id,
                new: true
            }

            this.setState({
                newPatient: post,
                selectedPatient: post,
                newPatientDate: false,
                newPatientName: false,
                newPatientGender: false,
                addNewPatient: false
            })
        }


    }
    renderAddNewPatient() {
        let { newPatientDate, newPatientName, newPatientGender } = this.state
        return (
            <Modal className='add_Patient' open={true}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px', alignItems: 'center', fontFamily: 'tfBold' }}>
                    <div style={{ color: 'red', width: '40px' }}
                        onClick={() =>
                            this.setState({
                                addNewPatient: false,
                                newPatientDate: false,
                                newPatientName: false,
                                newPatientGender: false,
                            })}>
                        {Strings.Cancel}
                    </div>
                    <div style={{ padding: '8px', fontSize: '18px', fontFamily: 'tfBold' }}>{Strings.Information}</div>
                    <div style={{ color: 'var(--themeColor)', width: '40px' }} onClick={() => this.submitNewPatient()}>{Strings.OK}</div>
                </div>
                <div style={{ textAlign: 'initial' }}>
                    <div style={{ padding: '8px' }}>
                        <div style={{ fontFamily: 'tfMedium', fontSize: '16px', color: '#cacaca', padding: '4px 0' }}>{Strings['Full Name']}</div>
                        <Input className='add_Patient_input' value={newPatientName ? newPatientName : ''} fluid onChange={(e, { value }) => this.setState({ newPatientName: value })} />
                    </div>
                    <div style={{ padding: '8px' }}>
                        <div style={{ fontFamily: 'tfMedium', fontSize: '16px', color: '#cacaca', padding: '4px 0' }}>{Strings.Gender}</div>
                        <Dropdown
                            search
                            selection
                            value={Strings[newPatientGender]}
                            className='patient_Gender'
                            style={{ textAlign: 'initial' }}
                            onChange={(e, { value }) => this.setState({ newPatientGender: value })}
                            options={[{ key: 'male', value: 'Male', text: 'Male' }, { key: 'female', value: 'Female', text: 'Female' }]} />
                    </div>
                    <div style={{ padding: '8px' }}>
                        <div style={{ fontFamily: 'tfMedium', fontSize: '16px', color: '#cacaca', padding: '4px 0' }}>{Strings['Date Of Birth']}</div>
                        <DatePicker
                            onChange={(date) => this.setState({ newPatientDate: date?.format('MMM DD, YYYY') })}
                            value={newPatientDate}
                            format="MMM DD, YYYY"
                            shadow={true}
                            className="rmdp-mobile"
                            style={{
                                width: "100%",
                                borderRadius: '4px',
                                height: "39px",
                                fontSize: "14px",
                                padding: "3px 10px"
                            }}
                        />
                    </div>
                </div>
            </Modal>
        )

    }
    renderConfirmPopup() {
        let { selectedDoctor, rescadual } = this.state
        return (
            < Modal open={true} style={{ textAlign: 'center' }}>
                <Modal.Header>{Strings.Alert}</Modal.Header>
                <Modal.Content>
                    {
                        rescadual ?
                            <div>{Strings['You have rescheduled an appointment with Dr.']}{getName(selectedDoctor, "name")} {Strings[', Are you sure?']}</div>
                            :
                            <div>{Strings['You have rescheduled an appointment with Dr.']}{getName(selectedDoctor, "name")} {Strings[', Are you sure?']}</div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => this.setState({ confirmPopup: false })}>{Strings.Cancel}</Button>
                    <Button color='blue' onClick={() => this.bookAppointment()}>{rescadual ? Strings.Reschedule : Strings.Confirm}</Button>
                </Modal.Actions>
            </Modal >
        )

    }
    renderPaymentSelection() {
        let { selectedDoctor, paymentType, selectedCard, selectedInsurance } = this.state
        let { user, providers, paymentOptions } = this.props
        let { insurnces } = providers
        let { userCards, insurances } = user

        let optimizeInsurances = insurances?.map(rec => {
            let insurance = insurnces?.find(item => item.id === rec.insurance)
            if (insurance) {
                rec.insuranceName = insurance?.name
                if (insurance?.name_ar) {
                    rec.insuranceName_ar = insurance?.name_ar
                }
                return rec
            }
        })
        optimizeInsurances = optimizeInsurances?.filter(itm => itm !== undefined)

        return (
            <div style={{ padding: '8px', minHeight: '90vh' }}>
                {this.renderCard(selectedDoctor)}
                <div style={{ fontFamily: 'tfBold', fontSize: '18px', textAlign: 'initial', padding: '16px 8px 8px' }}>
                    {Strings['Payment Option']}
                </div>
                {
                    paymentOptions?.map((item, index) => {
                        return (
                            <div
                                key={item}
                                onClick={() => this.setState({ paymentType: item })}
                                style={{
                                    textAlign: 'initial',
                                    padding: '12px 12px 0',
                                    margin: '12px 16px',
                                    borderRadius: '8px',
                                    justifyContent: 'space-between',
                                    transition: 'all 300ms linear'
                                }}>
                                <Checkbox style={{ fontSize: "16px", paddingBottom: '8px' }} checked={paymentType === item} radio label={Strings[item]} />
                                {
                                    paymentType === 'Card' && item === 'Card' ?
                                        <div>
                                            {
                                                userCards && Object.keys(userCards).map(key => {
                                                    let rec = { ...userCards[key], id: key }
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                this.setState({ selectedCard: rec })
                                                            }}
                                                            key={rec.id}
                                                            style={{
                                                                display: 'flex',
                                                                color: (selectedCard?.id === rec?.id ? '#fff' : '#4d4d4d'),
                                                                backgroundColor: (selectedCard?.id === rec?.id ? 'var(--themeColor)' : '#cacaca30'),
                                                                padding: '10px',
                                                                alignItems: 'center', border: '1px solid #cacaca',
                                                                borderRadius: '8px',
                                                                margin: '8px 0'
                                                            }}>
                                                            <Icon name='credit card' style={{ color: (selectedCard?.id === rec?.id ? '#fff' : 'var(--themeColor)'), margin: '0', fontSize: "22px" }} />
                                                            <div style={{ padding: '0 10px' }}>
                                                                <div style={{ padding: '2px 0', fontFamily: 'tfMedium', fontSize: "16px" }}>{`XXXX XXXX${rec?.savedCard?.last_four}`}</div>
                                                                <div style={{ fontFamily: 'tfMedium', fontSize: '12px' }}>{rec?.savedCard?.brand}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                onClick={() => {
                                                    this.setState({ selectedCard: false })
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    backgroundColor: (!selectedCard ? 'var(--themeColor)' : '#cacaca30'),
                                                    padding: '16px 8px',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    border: '1px solid #cacaca',
                                                    borderRadius: '8px',
                                                    margin: '8px 0',
                                                    color: !selectedCard ? '#fff' : '#4d4d4d'
                                                }}>
                                                <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                                                    <Icon name='credit card' style={{ color: !selectedCard ? '#fff' : 'var(--themeColor)', margin: '0', fontSize: "22px" }} />
                                                    <div style={{ padding: '0 8px', fontFamily: 'tfMedium' }}>{Strings["Use New Card"]}</div>
                                                </div>
                                            </div>
                                        </div>
                                        : paymentType === 'Insurance' && item === 'Insurance' ?
                                            <div>
                                                {
                                                    optimizeInsurances?.map(rec => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    this.setState({ selectedInsurance: rec })
                                                                }}
                                                                key={rec?.insurance}
                                                                style={{
                                                                    display: 'flex',
                                                                    backgroundColor: (selectedInsurance?.insurance === rec?.insurance ? 'var(--themeColor)' : '#cacaca30'),
                                                                    padding: '10px',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #cacaca',
                                                                    borderRadius: '8px',
                                                                    margin: '8px 0',
                                                                    color: (selectedInsurance?.insurance === rec?.insurance ? '#fff' : '#4d4d4d')
                                                                }}>
                                                                <Icon name='umbrella' style={{ color: selectedInsurance?.insurance === rec?.insurance ? '#fff' : 'var(--themeColor)', fontSize: "22px" }} />
                                                                <div style={{ padding: "0 10px", color: selectedInsurance?.insurance === rec?.insurance ? '#fff' : '#4d4d4d' }}>
                                                                    <div style={{ fontSize: '16px', fontFamily: 'tfMedium' }}>{[getName(rec, 'insuranceName'), rec?.network].join(' - ')}</div>
                                                                    <div style={{ fontSize: "12px", fontFamily: 'tfThin' }}>{`Expires: ${rec?.expiryDate}`}</div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div
                                                    onClick={() => {
                                                        this.setState({ addNewInsu: true })
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        backgroundColor: '#cacaca30',
                                                        padding: '12px 8px',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        border: '1px solid #cacaca',
                                                        borderRadius: '8px',
                                                        margin: '8px 0'
                                                    }}>
                                                    <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                                                        <Icon name='umbrella' style={{ color: 'var(--themeColor)', fontSize: "22px" }} />
                                                        <div style={{ padding: '0 8px', fontFamily: 'tfMedium' }}>{Strings['Add New Insurance']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''

                                }
                            </div>
                        )
                    })
                }
            </div>
        )

    }
    renderCheckout() {
        let { user } = this.props
        let { selectedDate,
            selectedClinic,
            selectedDoctor,
            selectedPatient,
            selectedService,
            selectedSpecilaty,
            selectedSlot,
            selectedCard,
            selectedInsurance,
            paymentType } = this.state;
        let { userCards } = user
        let array = [
            { key: 'Provider', icon: 'doctor', name: getName(selectedDoctor, "name"), detail: getName(selectedSpecilaty, "name") },
            { key: 'Facility', icon: 'hospital outline', name: getName(selectedClinic, "facilityName"), detail: [getName(selectedClinic, "address")?.area, getName(selectedClinic, "address")?.city].join(' - ') },
            { key: 'Service', icon: 'stethoscope', name: getName(selectedService, "name") },
            { key: 'Date and Time', icon: 'calendar outline', name: (selectedDate.format('MMM DD, yyyy') + ' at ' + selectedSlot) },
        ]
        let element;
        switch (paymentType) {
            case 'Cash':
                element = { key: 'Payment Method', icon: 'credit card outline', name: Strings[paymentType] }
                array.push(element)
                break
            case 'Card':
                element = { key: 'Payment Method', icon: 'credit card outline', name: Strings[paymentType], detail: selectedCard ? `XXXX${selectedCard?.savedCard?.last_four} ${selectedCard?.savedCard?.brand}` : 'New Card' }
                array.push(element)
                break
            case 'Insurance':
                element = { key: 'Payment Method', icon: 'credit card outline', name: `${selectedInsurance?.insuranceName} ${Strings[paymentType]}`, detail: selectedInsurance?.network }
                array.push(element)
                break
            default: break
        }
        return (
            <div style={{ minHeight: '90vh' }}>
                <div style={{ padding: '8px', textAlign: 'initial', borderBottom: '1px solid #cacaca' }}>
                    <div style={{ fontFamily: 'tfBold', fontSize: '16px', padding: '8px 0', color: '#4d4d4d' }}>{Strings['Booking For']}</div>
                    <div style={{ backgroundColor: 'var(--themeColor)', color: '#fff', borderRadius: '8px', padding: '8px', fontFamily: 'tfMedium' }}>
                        <div style={{ padding: '2px 0' }}>{selectedPatient.name}</div>
                        <div style={{ padding: '2px 0' }}>{selectedPatient.birthday}</div>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #cacaca' }}>
                    {
                        array.map((itm, index) => {
                            return (
                                <div key={itm.key} style={{ margin: '8px', display: 'flex', justifyContent: 'space-between', padding: '8px 0', alignItems: 'center', borderBottom: index === array.length - 1 ? 'none' : '1px solid #cacaca' }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'initial' }}>
                                        <Icon style={{ fontSize: '16px' }} name={itm.icon} />
                                        <div style={{ padding: '0 8px' }}>
                                            <div style={{ fontFamily: 'tfMedium', fontSize: '16px', color: '#cacaca' }}>{Strings[itm.key]}</div>
                                            <div style={{ fontFamily: 'tfMedium', color: '#4d4d4d', padding: '4px 0' }}>{itm.name}</div>
                                            <div style={{ fontFamily: 'tfThin' }}>{itm.detail}</div>
                                        </div>
                                    </div>
                                    {
                                        itm.key === 'Payment Method' && paymentType === 'Card' && <div
                                            style={{ fontFamily: 'tfBold', fontSize: '16px', color: 'var(--themeColor)', cursor: 'pointer' }}
                                            onClick={() => this.setState({ selectCard: userCards })}
                                        >{Strings.Change}</div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between' }}>
                    <div style={{ fontFamily: 'tfBold' }}>
                        {Strings['Total Amount']}
                    </div>
                    <div style={{ color: 'var(--themeColor)', fontFamily: 'tfMedium' }}>
                        {selectedService?.fees} {process.env.REACT_APP_CURRNECY}
                    </div>
                </div>
            </div>
        )
    }
    rencerCardSelection(selectCard) {
        let cards = Object.keys(selectCard).map(key => {
            return { ...selectCard[key], id: key }
        })

        return (<Modal className='add_Patient' open={true}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px', alignItems: 'center', fontFamily: 'tfBold' }}>
                <div style={{ width: '10px' }} />
                <div style={{ padding: '8px', fontSize: '18px', fontFamily: 'tfBold' }}>
                    {
                        Strings['Select Card']}
                </div>
                <Icon style={{ color: 'red', width: '10px', fontSize: '18px' }} name='times' onClick={() => this.setState({ selectCard: false })} />

            </div>
            <div style={{ overflow: 'auto', height: 'calc(50vh - 50px)' }}>
                {
                    cards.map(rec => {
                        return (
                            <div
                                onClick={() => {
                                    this.setState({ selectedCard: rec, selectCard: false })
                                }}
                                key={rec.id}
                                style={{
                                    display: 'flex',
                                    padding: '18px 8px',
                                    justifyContent: 'space-between',
                                    border: '1px solid #cacaca',
                                    borderRadius: '8px',
                                    margin: '8px'
                                }}>
                                <div style={{ display: 'flex', fontSize: '16px' }}>
                                    <Icon name='credit card' style={{ color: 'var(--themeColor)' }} />
                                    <div>{`XXXXXX${rec?.savedCard?.last_four}`}</div>

                                </div>
                                <div style={{ color: '#cacaca', fontFamily: 'tfBold', fontSize: '12px' }}>{rec?.savedCard?.brand}</div>

                            </div>
                        )
                    })
                }
                <div onClick={() => this.setState({ selectedCard: false, selectCard: false })}
                    style={{
                        display: 'flex',
                        padding: '18px 8px',
                        color: 'var(--themeColor)',
                        fontSize: '18px',
                        fontFamily: 'tfBold',
                        justifyContent: 'center',
                        border: '1px solid #cacaca',
                        borderRadius: '8px',
                        margin: '8px'
                    }}>
                    {Strings['Use New Card']}
                </div>

            </div>

        </Modal>
        )
    }
}
const mapStateToProps = ({ providers, user, helpers }) => {
    return {
        providers,
        user: user?.user,
        paymentOptions: helpers?.helpers?.paymentOptions
    }

}

export default connect(mapStateToProps, {})(withRouter(index));