import React from 'react'
import '../App.css'
import './videochat.css'
import 'firebase/database'
import classnames from 'classnames'
import { Button } from 'semantic-ui-react'
export default class VideoChat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      userToCall: null,
      username: false,
      mic: true,
      video: true,
      showControl: true
    }
    this.flipControls = this.flipControls.bind(this)
  }

  componentDidMount() {
    let url = new URL(window.location.href)
    let callId = url.searchParams.get('callid')
    let userUid = url.searchParams.get('uuid')
    let { meetindID } = this.props
    if (callId) {
      console.log('callIdcallId', callId)
      this.setState({ username: userUid || "string" }, () => {
        setTimeout(() => {
          this.onLoginClicked()
          this.setState({ userToCall: callId }, () => {
            this.onStartCallClicked()
          })
        }, 1500)
      })
    }
    setTimeout(() => {
      this.setState({ showControl: false })
    }, 5000)
    document.getElementById('videos').addEventListener('click', this.flipControls)
  }
  componentWillUnmount() {
    document.getElementById('videos').removeEventListener('click', this.flipControls)
  }
  flipControls(e) {
    let elemet = e.target
    console.log("elemet", elemet.className, elemet.class)
    if (elemet.id === "controlers"
      || elemet.className.includes("microphone")
      || elemet.className === "video icon"
      || elemet.className === "call"
    ) {
      return
    }
    let { showControl } = this.state
    console.log("showControl", showControl)
    if (!showControl) {
      setTimeout(() => {
        this.setState({ showControl: false })
      }, 5000)
    }
    this.setState({ showControl: !showControl })
  }
  onLoginClicked = async () => {
    await this.props.onLogin(this.state.username)
    this.setState({
      isLoggedIn: true
    })
  }


  onStartCallClicked = () => {
    this.props.startCall(this.state.username, this.state.userToCall)
  }

  renderVideos = () => {
    let { mic, video, showControl, username, userToCall } = this.state
    let { selectAppointment } = this.props
    return <div id='videos' style={{ justifyContent: 'center' }} className={classnames('videos', { active: this.state.isLoggedIn })}>
      {
        !this.props.connectedUser && <div style={{ position: 'absolute' }}>
          wating for other peer to connect...
        </div>
      }
      <div>
        <video style={{
          position: 'absolute',
          zIndex: 99,
          top: '0',
          right: '0',
          height: 'auto',
          width: '150px',
          borderRadius: '0 0 8px 8px',
          transform: 'scaleX(-1)'
        }} ref={this.props.setLocalVideoRef} muted autoPlay playsInline></video>
      </div>
      <video style={{ objectFit: 'cover', position: 'absolute', top: '0', right: '0', width: '100%', height: '100vh', backgroundColor: '#000' }} ref={this.props.setRemoteVideoRef} autoPlay playsInline></video>

      <div id='controlers' className={showControl ? 'controlers active' : 'controlers inactive'}>
        {
          selectAppointment ?
            <div className='callDetails'>
              {selectAppointment.patientName}
            </div>
            : ''
        }

        <div className='btns'>
          <div>
            <Button
              id='controlers'
              icon={mic ? 'microphone' : 'microphone slash'}
              circular
              style={{ background: '#00000090', color: '#fff', width: '55px', height: '55px', borderRadius: '50%', margin: '8px' }}
              onClick={() => {
                this.props.updateState({ mic: !mic, video })
                this.setState({ mic: !mic })
              }}
            />
          </div>
          <Button
            id='controlers'
            style={{ background: 'red', color: '#fff', width: '65px', height: '65px', borderRadius: '50%', margin: '8px' }}
            icon='call' circular
            onClick={() => {
              this.props.dismissCall(username, userToCall)
            }}
          />

          <div >
            {
              video ?
                <Button
                  id='controlers'
                  style={{ background: '#00000090', color: '#fff', width: '55px', height: '55px', borderRadius: '50%', margin: '8px' }}
                  icon='video'
                  circular
                  onClick={() => {
                    this.props.updateState({ video: !video, mic })
                    this.setState({ video: !video })
                  }} />
                :
                <i
                  id='controlers'
                  class="fas fa-video-slash"
                  style={{ background: '#00000090', color: '#fff', width: '55px', height: '55px', borderRadius: '50%', margin: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  onClick={() => {
                    this.props.updateState({ video: !video, mic })
                    this.setState({ video: !video })
                  }}></i>
            }

          </div>

        </div>

      </div>

    </div>
  }

  renderForms = () => {
    return ''
  }

  render() {
    console.log("connectedUser", this.props.connectedUser)
    return <section id="container" style={{ width: '100%' }}>
      {this.renderVideos()}

    </section>
  }
}
