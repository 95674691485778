import React, { Component } from 'react';
import { getName } from '../../helpers';
import Strings from '../../strings';

class tagView extends Component {
    state = {
        collapse: true
    }
    render() {
        let { tags, title } = this.props
        let { collapse } = this.state
        let array = JSON.parse(JSON.stringify(tags))
        if (collapse) {
            array = array.slice(0, 3)
        }

        return (
            <div className='section'>
                <div className='sectionHead'>
                    {Strings[title]}
                </div>
                <div >
                    {
                        array.map((item, index) => {
                            return (
                                <span className='tags' key={item?.id || index} >
                                    {getName(item, "name")}
                                </span>
                            )

                        })
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={() => this.setState({ collapse: !collapse })}>
                    {collapse ? Strings.showAll : Strings.showLess}
                </div>
            </div>
        );
    }
}

export default tagView;

