import React from 'react'
import { createOffer, initiateConnection, startCall, sendAnswer, addCandidate, initiateLocalStream, listenToConnectionEvents } from '../modules/RTCModule'
import firebase from 'firebase/app'
import 'firebase/database'
import { doOffer, doAnswer, doLogin, doCandidate, doLeaveNotif } from '../modules/FirebaseModule'
import 'webrtc-adapter'
import VideoChat from './VideoChat'
import { Loader, Dimmer } from 'semantic-ui-react'
class VideoChatContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      database: null,
      connectedUser: null,
      localStream: null,
      localConnection: null
    }
    this.localVideoRef = React.createRef()
    this.remoteVideoRef = React.createRef()
  }

  componentDidMount = async () => {
    // getting local video stream
    const localStream = await initiateLocalStream(true, true)

    let rightSideElement = document.getElementById('rightSide')
    if (rightSideElement) {
      rightSideElement.style.display = 'none'
    }
    const localConnection = await initiateConnection()
    this.setState({
      database: firebase.database(),
      localStream,
      localConnection
    })
  }
  componentWillUnmount() {
    let rightSideElement = document.getElementById('rightSide')
    if (rightSideElement && window.screen.width >= 900) {
      rightSideElement.style.display = 'flex'
    }
  }

  startCall = async (username, userToCall) => {
    const { localConnection, database, localStream } = this.state
    listenToConnectionEvents(localConnection, username, userToCall, database, this.remoteVideoRef, doCandidate)
    // create an offer
    createOffer(localConnection, localStream, userToCall, doOffer, database, username)
  }

  onLogin = async (username) => {
    return await doLogin(username, this.state.database, this.handleUpdate)
  }

  setLocalVideoRef = ref => {
    let { localStream } = this.state
    this.localVideoRef = ref
    if (ref) {
      this.localVideoRef.srcObject = localStream
    }
  }

  setRemoteVideoRef = ref => {
    this.remoteVideoRef = ref
  }

  handleUpdate = (notif, username) => {
    const { localConnection, database, localStream } = this.state

    if (notif) {
      console.log("notif.type", notif.type)
      switch (notif.type) {
        case 'offer':
          this.setState({
            connectedUser: notif.from
          })

          listenToConnectionEvents(localConnection, username, notif.from, database, this.remoteVideoRef, doCandidate)

          sendAnswer(localConnection, localStream, notif, doAnswer, database, username)
          break
        case 'answer':

          this.setState({
            connectedUser: notif.from
          })
          startCall(localConnection, notif)
          break
        case 'candidate':
          addCandidate(localConnection, notif)
          break
        case 'leave':
          this.leavCall()
          break;
        default:
          break
      }
    }
  }
  leavCall = async () => {
    let { localStream, localConnection } = this.state
    if (localStream) {
      const tracks = await localStream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      localConnection.close()
      this.setState({
        localStream: false,
        localConnection: false
      }, () => {
        this.props.history.push("/appointments");
      })
    }
  }
  render() {
    let { localStream, localConnection, database } = this.state
    let { selectAppointment } = this.props
    if (!localStream) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )
    }
    return (
      <VideoChat
        startCall={this.startCall}
        onLogin={this.onLogin}
        selectAppointment={selectAppointment}
        setLocalVideoRef={this.setLocalVideoRef}
        setRemoteVideoRef={this.setRemoteVideoRef}
        connectedUser={this.state.connectedUser}
        meetindID={this.props.meetindID}
        dismissCall={async (user, userToCall) => {
          const tracks = await localStream.getTracks()
          tracks.forEach(track => {
            console.log("tracktrack", track)
            track.stop()
          })
          localConnection.close()
          this.setState({
            localStream: false,
            localConnection: false
          }, () => {
            doLeaveNotif(userToCall, database, user)
            this.props.history.push("/appointments");
          })
        }}
        updateState={async (state) => {
          console.log(state);
          localStream.getVideoTracks()[0].enabled = state.video
          localStream.getAudioTracks()[0].enabled = state.mic
        }}
      />
    )
  }
}

export default VideoChatContainer
