import moment from 'moment';
import getDistance from 'geolib/es/getDistance';

export const getClinicDistance = (clinic, userLocation) => {
    let clinicLocation = clinic.location
    if (clinicLocation && userLocation) {
        let distance = (getDistance(clinicLocation, userLocation) / 1000).toFixed(2)
        return distance
    }
    return 900000

}

export const optimizeDate = (date) => {
    if (date) {
        let optimized = date.toLowerCase().replace(':am', ':AM').replace(':pm', ':PM').replace(' am', ':AM').replace(' pm', ':PM')
        let spliteed = optimized.split(":")
        return { hours: (spliteed[2] === 'PM' ? (parseInt(spliteed[0]) + 12) : parseInt(spliteed[0])), min: parseInt(spliteed[1]), flag: spliteed[2] }
    }

}
export const checkOpenNow = (clinic) => {
    let { openingDays } = clinic
    let weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
    let today = weekDays[(new Date()).getDay()]
    if (openingDays) {
        let { opened, isTwentyFour, isSplitShift, fromTime, toTime, pmFromTime, pmToTime } = openingDays[today]
        if (opened) {
            if (isTwentyFour) {
                return 'Open Now'
            }
            if (isSplitShift) {
                let optimizedFrom = moment().set({ hour: optimizeDate(fromTime).hours, minute: optimizeDate(fromTime).min })
                let optimizedTo = moment().set({ hour: optimizeDate(toTime).hours, minute: optimizeDate(toTime).min })
                let optimizedPMFrom = moment().set('hours', optimizeDate(pmFromTime).hours).set('minutes', optimizeDate(pmFromTime).min)
                let optimizedPMTo = moment().set('hours', optimizeDate(pmToTime).hours).set('minutes', optimizeDate(pmToTime).min)
                if (moment().isBetween(optimizedFrom, optimizedTo) || moment().isBetween(optimizedPMFrom, optimizedPMTo)) {
                    return 'Open Now'
                }
            } else {
                let optimizedFrom = moment().set({ hour: optimizeDate(fromTime).hours, minute: optimizeDate(fromTime).min })
                let optimizedTo = moment().set({ hour: optimizeDate(toTime).hours, minute: optimizeDate(toTime).min })
                if (moment().isBetween(optimizedFrom, optimizedTo)) {
                    return 'Open Now'
                }
            }
        }
    }
    return 'Closed Now'
}
export const getName = (itm, key) => {
    let lang = localStorage.getItem('lang')
    if (lang === "ar") {
        let araKey = key + "_ar"
        if (itm && itm[araKey]) {
            return itm[araKey]
        }
    }
    return itm ? itm[key] : false
}