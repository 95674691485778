
import React from 'react';
import { getName } from '../../helpers';
import Strings from '../../strings';

const about = (props) => {
    return (
        <div className='section'>
            <div className='sectionHead'>
                {Strings.Bio}
        </div>
            <div className='sectionDesc'>
                {
                    getName(props.doctor,"shortBio")
                }
            </div>
        </div>
    );
};

export default about;