import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './index.css'
import { Link } from 'react-router-dom'
import { checkOpenNow, getClinicDistance, getName } from '../helpers'
import Strings from '../strings';
function FacilityCard(props) {
    let { clinic } = props
    let open = checkOpenNow(clinic)
    const [userLocation, setUserLocation] = useState(false)
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(pos => {
            let coords = pos?.coords
            if (coords) {
                setUserLocation({ latitude: coords.latitude, longitude: coords.longitude })
            }

        })
    }, [])
    return (
        <Link to={'/facility-profile?key=' + clinic.facilityId} className='facility-card'>
            <div style={{ display: 'flex', padding: '12px 4px', alignItems: 'center' }}>
                <img className='logo' src={clinic.logoUrl} />
                <div className='details'>
                    <div style={{ paddingBottom: '4px' ,width:"99%"}}>
                        <div className='facility-name'>
                            {getName(clinic, "facilityName")}
                        </div>
                        <div style={{ color: '#a7a7a7', fontSize: '13px' }}>
                            {[getName(clinic, "address")?.area, getName(clinic, "address")?.city].join(' - ')}
                        </div>
                    </div>

                    <div style={{ color: open !== 'Open Now' ? 'red' : '#77C344', fontSize: '13px' }}>
                        {Strings[open]}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 6px' }}>
                <div style={{ fontSize: '12px' ,display:'flex'}}>
                    <Icon name='eye'></Icon>
                    <div style={{padding:'0 4px'}}>{clinic.views} {Strings.views} </div>

                </div>
                <div style={{ fontSize: '12px' }}>
                    <Icon name='location arrow'></Icon>
                    {
                        userLocation && getClinicDistance(clinic, userLocation) + " " + Strings.km
                    }
                </div>
            </div>

        </Link>
    );
}

export default FacilityCard;