import './index.css'
import Footer from '../footer'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { database, firestore } from '../fire';
import moment from 'moment';
import { Modal, Button, Icon, Dimmer, TextArea } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReviewModal from './reviewModal'
import Strings from '../strings';
import { getName } from '../helpers'
function mapStatus(status, online) {
    switch (status) {
        case 'new':
            return { lable: 'Pending', color: '#F43A59' }
        case 'pending':
            return { lable: 'Pending', color: '#F43A59' }
        case 'accepted':
            return { lable: 'Confirmed', color: '#06B814' }
        case 'discharged':
            return { lable: 'Done', color: '#3498DB' }
        case 'succeeded':
            if (online && online.type !== 'leave') {
                return { lable: 'Doctor is waiting for you', color: '#FC9338' }
            }
            return { lable: 'Arrived', color: '#FC9338' }
        case "rejected": case "failed": case "canceled": case "removed":
            return { lable: 'Canceled', color: '#797979' }
        default: {
            return { lable: status, color: '#F43A59' }
        }
    }
}
class Index extends Component {
    state = {
        tags: [
            { label: 'Active', key: 'active', filters: ['new', 'pending', 'accepted', 'arrived', 'succeeded'] },
            { label: 'Previous', key: 'prev', filters: ['discharged'] },
            { label: 'Canceled', key: 'canceled', filters: ['rejected', 'failed', 'canceled', 'removed'] },

        ],
        selectedTag: 'active',
        score: {
            score_0: 2,
            score_1: 2,
            score_2: 2,
            score_3: 2
        }
    }
    cancelAppointment(appointment) {
        firestore().collection('bookings').doc(appointment.id).update({
            status: 'rejected',
            canceled: Date.now()
        }).then(() => {
            let appointmentDate = moment(appointment.dateTS).format('MMM DD, yyyy');
            let deletionSlots = [moment(appointment.dateTS).format('hh:mm A')];
            let addedValue = 15

            while (appointment.duration >= addedValue) {
                let deletionVal = moment(appointment.dateTS).add(addedValue, 'minutes').format('hh:mm A')
                deletionSlots.push(deletionVal);
                addedValue = addedValue + 15;
            }

            firestore().collection('slots').doc(appointment.doctor).collection(appointment.facilityId).doc(appointmentDate).set({
                [appointmentDate]: firestore.FieldValue.arrayRemove(...deletionSlots)
            }, { merge: true }).then(() => {
                this.setState({ showPopup: false, status: false })
            })
        })
    }
    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        let { user } = this.props
        if (user) {
            database().ref('notifs').child(user?.uid).on('value', snap => {
                this.setState({ online: snap.val() })
            })
        }
    }
    render() {
        let { tags, selectedTag, showPopup, recommend, online } = this.state
        let { user, providers, services } = this.props
        let activeFilter = tags.find(rec => rec.key === selectedTag)
        let array = user?.userBookings?.filter(rec => activeFilter.filters.includes(rec.status) && rec.dateTS);
        array = array?.sort((a, b) => {
            return new Date(b.dateTS) - new Date(a.dateTS);
        })


        return (
            <div className='appointment'>
                <div style={{ position: 'sticky', top: '0' }}>
                    <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                        <div style={{ color: '#fff', textAlign: 'center', width: '100%', fontFamily: 'tfBold', fontSize: '16px' }}>{Strings.Appointments}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '4px 0', backgroundColor: '#fff' }}>
                        {
                            tags.map(item =>
                                <div
                                    onClick={() => this.setState({ selectedTag: item.key })}
                                    key={item.key}
                                    style={{
                                        borderRadius: '4px',
                                        width: '30%',
                                        padding: '8px',
                                        margin: '8px 4px',
                                        border: '1px solid var(--themeColor)',
                                        color: (selectedTag === item.key ? '#fff' : 'var(--themeColor)'),
                                        backgroundColor: (selectedTag === item.key ? 'var(--themeColor)' : '#fff')
                                    }}>
                                    {Strings[item.label]}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div style={{ minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '12px' }}>
                    {
                        array?.length ? array.map(item => {
                            let activeDoctor = providers.find(rec => rec.id === item.doctor);
                            let status = mapStatus(item.status, (online?.from === item.id ? online : false))
                            console.log("statusstatus",status)
                            let activeService = item.service === 'onlineConsult' ?
                                { name: 'Online Consultation' }
                                : item.service === 'inpersonConsult' ? { name: 'In Clinic Consultation' }
                                    : (services.find(rec => rec.id === item.service) || false)
                            return (
                                <div key={item.id} className='appointment-card'>
                                    <div style={{ display: 'flex', margin: '0 8px', padding: '12px 0', alignItems: 'center', borderBottom: '1px solid #cacaca' }}>
                                        <img src={activeDoctor?.profilePicture} style={{ width: '50px', height: '50px', borderRadius: '4px', objectFit: 'cover' }} />
                                        <div style={{ padding: '0 8px' }}>
                                            <div style={{ color: '#4d4d4d', fontFamily: 'tfMedium' }}>{getName(activeDoctor, "name")}</div>
                                            <div style={{ color: '#b8b8b8' }}>{

                                                getName(activeService, "name") || ''

                                            }</div>
                                        </div>
                                    </div>
                                    <div style={{ padding: '0 12px', borderBottom: '1px solid #cacaca' }}>
                                        <div style={{ padding: '2px 0', fontFamily: 'tfMedium' }}>
                                            <div style={{ color: '#b8b8b8', fontSize: '12px' }}>
                                                {Strings.Clinic}
                                            </div>
                                            <div style={{ color: '#4d4d4d', fontSize: '14px' }}>
                                                {item.facilityName}
                                            </div>
                                        </div>
                                        <div style={{ padding: '2px 0', fontFamily: 'tfMedium' }}>
                                            <div style={{ color: '#b8b8b8', fontSize: '12px' }}>
                                                {Strings.Patient}
                                            </div>
                                            <div style={{ color: '#4d4d4d', fontSize: '14px' }}>
                                                {item.patientName}
                                            </div>
                                        </div>
                                        <div style={{ padding: '2px 0', fontFamily: 'tfMedium' }}>
                                            <div style={{ color: '#b8b8b8', fontSize: '12px' }}>
                                                {Strings.Service}
                                            </div>
                                            <div style={{ color: '#4d4d4d', fontSize: '14px' }}>
                                                {getName(activeService, "name") || ''}
                                            </div>
                                        </div>
                                        <div style={{ padding: '2px 0', fontFamily: 'tfMedium' }}>
                                            <div style={{ color: '#b8b8b8', fontSize: '12px' }}>
                                                {Strings.Date}
                                            </div>
                                            <div style={{ color: '#4d4d4d', fontSize: '14px' }}>
                                                {item.date}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: '8px 12px', borderBottom: '1px solid #cacaca' }}>
                                        <div style={{ fontFamily: 'tfMedium' }}>
                                            <div style={{ color: '#b8b8b8', fontSize: '12px' }}>
                                                {Strings.Status}
                                            </div>
                                            <div style={{ color: status.color, fontSize: '14px', fontFamily: 'tfBold', textTransform: 'capitalize' }}>
                                                {Strings[status.lable]}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'center', fontFamily: 'tfMedium', color: 'var(--themeColor)' }}>
                                        {
                                            ['new', 'pending', 'accepted', 'succeeded'].includes(item.status) ?
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {
                                                        item.service === 'onlineConsult' && item.status === 'succeeded' ?
                                                            (online?.from === item.id) && (online?.type !== 'leave') ?
                                                                <Link to={`/call?callid=${item.id}&uuid=${user.uid}`} onClick={() => this.setState({})} style={{ width: '100%', backgroundColor: '#449044', color: '#fff', cursor: 'pointer', fontSize: '18px', borderRadius: '0 0 4px 4px', textDecoration: 'none', padding: '12px', }}>
                                                                    {Strings.Join}
                                                                </Link>
                                                                :
                                                                <div style={{ width: '100%', color: 'green', cursor: 'pointer', padding: '12px', opacity: '0.5' }} >
                                                                    {Strings['waiting for doctor...']}
                                                                </div>
                                                            :
                                                            <>
                                                                <div onClick={() => this.setState({ showPopup: item, status: 'cancel' })} style={{ width: '50%', cursor: 'pointer', color: 'red', borderRight: '1px solid #cacaca', padding: '12px', }}>
                                                                    {Strings.Cancel}
                                                                </div>
                                                                {

                                                                }

                                                                <Link to={item.status !== "succeeded" ? `/book?doctor_id=${item.doctor}&rescadual=${item.id}` : '#'}
                                                                    style={{ width: '50%', padding: '12px', color: 'var(--themeColor)', opacity: (item.status === "succeeded" ? '0.5' : '1') }}>
                                                                    {Strings.Reschedule}
                                                                </Link>
                                                            </>
                                                    }

                                                </div>
                                                :
                                                item.status === 'discharged' && !item.recommended ?

                                                    <div style={{ padding: '12px' }}>

                                                        <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => this.setState({ recommend: item })}>
                                                            {
                                                                [1, 2, 3, 4, 5].map(item => {
                                                                    return (
                                                                        <Icon
                                                                            key={item}
                                                                            name='star outline'
                                                                            className={4 >= item ? 'starts_fill' : 'starts'} />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div style={{ fontSize: '14px', color: '#cacaca', padding: '8px' }}>
                                                            {Strings['Tap Stars to review Doctor']}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div style={{ padding: '12px' }}>
                                                            {Strings['Book again']}
                                                        </div>
                                                    </>
                                        }

                                    </div>

                                </div>

                            )
                        })
                            :
                            <div style={{ padding: '50% 0' }}>
                                <Icon style={{ fontSize: '60px' }} name='searchengin' />
                                <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '20px', padding: '16px' }}>{Strings['No Appointments Found!']}</div>
                                <div style={{ padding: '12px 20%', color: '#4d4d4d80' }}>
                                    {
                                        Strings['Find your doctor and book your appointment easily!']
                                    }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Link to='/?search=true' style={{ color: '#fff', backgroundColor: 'var(--themeColor)', cursor: 'pointer', borderRadius: '18px', fontFamily: 'tfBold', padding: '12px 40px' }}>{Strings.Search}</Link>

                                </div>
                            </div>
                    }


                </div>
                {
                    showPopup && this.renderConfirmPopup(showPopup, this.state.status)
                }
                <Footer />
                {
                    recommend && <ReviewModal

                        recommend={recommend}
                        providers={providers}
                        user={user}
                        dismiss={() => this.setState({ recommend: false })} />
                }
            </div>
        );
    }
    renderConfirmPopup(item, flag) {
        let selectedDoctor = this.props.providers.find(rec => rec.id === item.doctor)
        return (
            < Modal open={true} style={{ textAlign: 'center' }}>
                <Modal.Header>{flag === 'cancel' ? 'Cancel Appointment' : 'Alert'}</Modal.Header>
                <Modal.Content>
                    {
                        flag === 'cancel' ?
                            <div>{Strings['Are you sure you want to cancel the appointment with Dr.']}{getName(selectedDoctor, "name")}</div>
                            :
                            <div>{Strings['You have requested an appointment with Dr.']}{getName(selectedDoctor, "name")} {Strings[', Are you sure?']}</div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => this.setState({ showPopup: false })}>{Strings["Don't Cancel"]}</Button>
                    <Button color='blue' onClick={() => this.cancelAppointment(item)}>{Strings.Confirm}</Button>
                </Modal.Actions>

            </Modal >
        )

    }

}

const mapStateToProps = ({ user, providers }) => {
    return { user: user?.user, providers: providers?.providers, services: providers?.services }
}

export default connect(mapStateToProps, {})(Index);