import { createAction } from 'redux-action';
export const GET_HELPERS = 'GET_HELPERS';
export const getHelpers = createAction(GET_HELPERS, (list) => {
    return list
});
const initial = {};

let ex2 = (state = initial, action) => {
    switch (action.type) {
        case GET_HELPERS:
            return {
                ...state,
                helpers: action.payload || []
            }
        default:
            return state;
    }
};
export default ex2