import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import Strings from '../strings';
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/mobile.css"

import moment from 'moment';
import { firestore } from '../fire';
function submit(user, dismiss) {
    if (user) {
        delete user.userBookings;
        user.birthday = moment(user.birthday).format("MMM DD, YYYY")
        firestore().collection('users').doc(user.uid).update(user).then(() => {
            dismiss()
        })
    }

}
function Information(props) {

    let [user, setUser] = useState(props.user)
    let [loading, setLoading] = useState(false)
    let [menu, setMenu] = useState([])
    useEffect(() => {
        setMenu([
            { label: "First Name", key: 'firstName', value: '', type: 'name' },
            { label: "Last Name", key: 'lastName', value: '', type: 'name' },
            { label: "Contact Number", key: 'number', value: '', type: 'number' },
            { label: "Email Address", key: 'email', value: '', type: 'email' },
            { label: "Date Of Birth", key: 'birthday', value: '' },
            { label: "Nationality", key: 'nationality', value: '', options: props?.nationalities?.map(rec => { return { key: rec, value: rec, text: Strings[rec] } }) },
            { label: "Gender", key: 'gender', value: '', options: [{ key: 'male', value: 'Male', text: Strings['Male'] }, { key: 'female', value: 'Female', text: Strings['Female'] }] },

        ])

    }, [])


    return (
        <div className='information'>
            <div style={{ paddingBottom: '60px' }}>
                {
                    menu.map(item => {
                        return (
                            <div key={item.key} style={{ padding: '8px', borderBottom: '1px solid #eae8e8', fontFamily: 'tfMedium' }}>
                                <div style={{ color: 'var(--themeColor)', padding: '4px 0' }}>{Strings[item.label]}</div>
                                {
                                    item.key === 'birthday' ?
                                        <DatePicker
                                            onChange={(date) => setUser({ ...user, birthday: date?.format('MMM DD, YYYY') })}
                                            value={user.birthday}
                                            format="MMM DD, YYYY"
                                            shadow={true}
                                            className="rmdp-mobile"
                                            style={{
                                                width: "100%",
                                                border: 'none',
                                                borderRadius: '0',
                                                boxShadow: 'none',
                                                height: "39px",
                                                fontSize: "14px",
                                            }}
                                        />
                                        : ["nationality", "gender"].includes(item.key) ?
                                            <Dropdown
                                                search
                                                selection
                                                value={user[item.key]}
                                                style={{ width: '100%', border: 'none', textAlign: 'initial' }}
                                                className='patient_Gender'
                                                onChange={(e, { value }) => setUser({ ...user, [item.key]: value })}
                                                options={item.options} />
                                            :
                                            <Input type={item.type} style={{ textAlign: 'initial' }} transparent fluid value={user[item.key]} onChange={(e, { value }) => setUser({ ...user, [item.key]: value })} />
                                }
                            </div>
                        )
                    })
                }
            </div>
            <Button loading={loading} className='submit' onClick={() => {
                setLoading(true)
                submit(user, props.dismiss)
            }}>
                Submit
            </Button>

        </div>
    );
}

export default Information;