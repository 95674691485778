import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import './header.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import { getName, checkOpenNow } from '../../helpers'
import Strings from '../../strings'
function optimizeDate(date) {
    if (date) {
        let optimized = date.toLowerCase().replace(':am', ':AM').replace(':pm', ':PM').replace(' am', ':AM').replace(' pm', ':PM')
        let spliteed = optimized.split(":")
        return { hours: (spliteed[2] === 'PM' ? (parseInt(spliteed[0]) + 12) : parseInt(spliteed[0])), min: parseInt(spliteed[1]), flag: spliteed[2] }
    }

}
class Header extends Component {
    render() {
        let { facility } = this.props
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: true
        };
        let open = checkOpenNow(facility)
        let pictures = []
        facility.pictures.map((rec, index) => {
            if (index === 0) {
                pictures = pictures.concat([rec, rec])
            } else {
                pictures.push(rec)
            }
            return false
        })
        return (
            <Slider {...settings} >
                {
                    pictures.map((item, index) => {
                        return (
                            <div style={{ minWidth: '100%' }} key={index}>
                                {index === 0 && <div style={{ position: 'absolute', width: `calc(100% / ${facility.pictures.length + 1})`, filter: 'opacity(0.3)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: ('url(' + item + ')'), minHeight: '100%' }} />}
                                <div style={{ position: 'relative', padding: ' 8px 8px 32px 8px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: index !== 0 ? ('url(' + item + ')') : '' }}>
                                    <div style={{ opacity: index === 0 ? 1 : 0 }}>
                                        <div className='views'>
                                            <Icon name='eye'></Icon>
                                            {facility.views} {Strings.views}
                                        </div>
                                        <div style={{ padding: '22px 0 0' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', padding: '12px' }}>
                                                <img className='logo' src={facility.logoUrl}></img>

                                            </div>
                                            <h1 className='name'>{getName(facility, "facilityName")}</h1>
                                            <div>{[getName(facility, "address")?.area, getName(facility, "address")?.city].join(' - ')}</div>
                                            <div className='open' style={open === 'Open Now' ? { color: '#77C344' } : { color: 'red' }}>{Strings[open]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider >
        );
    }
}

export default Header;