import React, { Component } from 'react';
import Strings from '../../strings';

class Clinics extends Component {
    render() {
        let { doctor } = this.props
        let { doctorExperience, doctorFelowship, doctorResidency, doctorDegrees } = doctor
        let array = []
        let existed = false

        if (doctorExperience) {
            let object = doctorExperience.map(rec => {
                existed = true
                return { spec: rec.speciality, year: rec.to.split('-')[0], country: rec.country, facility: rec.facilityName }
            })
            array.push({ label: "Experince", value: object })
        }
        if (doctorFelowship) {
            let object = doctorFelowship.map(rec => {
                existed = true

                return { spec: rec.speciality, year: rec.to.split('-')[0], country: rec.country, facility: rec.facilityName }
            })
            array.push({ label: "Fellowship", value: object })
        }
        if (doctorResidency) {
            let object = doctorResidency.map(rec => {
                existed = true
                return { spec: rec.speciality, year: rec.to.split('-')[0], country: rec.country, facility: rec.facilityName }
            })
            array.push({ label: "Residency", value: object })
        }
        if (doctorDegrees) {
            let object = doctorDegrees.map(rec => {
                existed = true
                return { spec: rec.degree, year: rec.to.split('-')[0], country: rec.country, facility: rec.facilityName }
            })
            array.push({ label: "Medical School", value: object })
        }
        if (!existed) {
            return ''
        }
        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings.Professional
                    }
                 </div>
                <div>

                    {
                        array.map((item, index) => {
                            if (!item.value.length){
                                return ''

                            }
                            return (
                                <div key={index}>
                                    <div style={{ fontSize: '16px', color: 'var(--themeColor)', padding: '4px 0' }}>
                                        {Strings[item.label]}
                                    </div>
                                    {
                                        item.value.map(rec => {
                                            return (
                                                <div style={{ display: 'flex', padding: '8px 0' }} key={rec.spec}>
                                                    <div style={{ fontSize: '12px', color: '#888888' }}>{rec.year}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ border: '1px solid #cacaca', margin: '0 8px' }}></div>
                                                        <div style={{ borderRadius: '50%', margin: '4px', width: '10px', height: '10px', position: 'absolute', backgroundColor: 'var(--themeColor)' }}></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ fontFamily: 'tfMedium' }}>{rec.spec}</div>
                                                        <div style={{ color: '#888888' }}>{rec.facility}</div>
                                                        <div style={{ color: '#888888' }}>{rec.country}</div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            )

                        })
                    }

                </div>
            </div>
        );
    }
}

export default Clinics;