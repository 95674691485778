import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Strings from '../../strings';
import { getName } from '../../helpers'
import './clinics.css'
class Clinics extends Component {
    render() {
        let { activeClinics, doctor } = this.props
        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings["Clinics Locations"]
                    }
                </div>
                <div style={{ padding: '8px 0' }}>
                    {
                        activeClinics.map(activeClinic => {
                            let array = [{ label: 'Facility Name', value: getName(activeClinic, "facilityName") },
                            { label: 'Address', value: [getName(activeClinic, "address")?.area, getName(activeClinic, "address")?.city].join(' - ') },
                            { label: 'Doctor Consultation Fees', value: ((doctor.bookingActive && doctor.bookingActive[activeClinic.facilityId]) || (doctor.consultFee && doctor.consultFee[activeClinic?.facilityId]) || '--') + ` ${process.env.REACT_APP_CURRNECY}` },
                            ]
                            return (
                                <div className='clinics-card' key={activeClinic.facilityId}>
                                    <Link to={'/facility-profile?key=' + activeClinic.facilityId} style={{ padding: '8px', width: '75%' }}>
                                        {
                                            array.map(item =>
                                                <div key={item.label} style={{ padding: '2px 0' }}>
                                                    <div style={{ fontSize: '11px', fontFamily: 'tfRegular', color: '#4d4d4d', height: '14px' }}>
                                                        {Strings[item.label]}
                                                    </div>
                                                    <div style={{ color: 'var(--themeColor)', fontSize: '12px' }}>
                                                        {item.value}
                                                    </div>
                                                </div>)
                                        }
                                        {
                                            activeClinic.bookingActive ?
                                                <Link to={`/book?facility_id=${activeClinic.facilityId}&doctor_id=${doctor.id}`} style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: 'var(--secondaryColorOpacity30)',
                                                    color: 'var(--secondaryColor)',
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                    borderRadius: '4px',
                                                    fontFamily: 'tfMedium',
                                                    cursor: 'pointer'
                                                }}>
                                                    {Strings.book}
                                                </Link>
                                                :
                                                <div style={{
                                                    width: '100%',
                                                    backgroundColor: 'var(--themeColorOpacity30)',
                                                    color: 'var(--themeColor)',
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                    borderRadius: '4px',
                                                    fontFamily: 'tfMedium',
                                                    cursor: 'pointer'
                                                }}>
                                                    {Strings.call}
                                                </div>


                                        }


                                    </Link>
                                    <img
                                        style={{
                                            width: '25%',
                                            backgroundColor: '#cacaca',
                                            borderTopRightRadius: '4px',
                                            borderBottomRightRadius: '4px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: `url("https://maps.googleapis.com/maps/api/staticmap?center=${activeClinic.location.latitude},${activeClinic.location.longitude}&zoom=14&size=118x162&key=AIzaSyCb34POFDf2Ww36X42ntRQLFvZdg-_73Jg")`
                                        }} />
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        );
    }
}

export default Clinics;