import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from './home'
import FacilityProfile from './facilityProfile/index'
import DoctorProfile from './doctorProfile/index'
import SearchView from './searchView/index'
import Booking from './bookingScreen';
import 'semantic-ui-css/semantic.min.css'
import React, { Component } from 'react';
import { connect } from "react-redux";
import { firestore, auth, messaging } from './fire'
import { getProviders, getFacilities, getSpecs, getInsurnces, getServices, getTopSpecs, getAmenities, getConditions } from './reducers/providers'
import { getHelpers } from './reducers/helpers'
import { getUser } from './reducers/user'
import { Dimmer, Loader } from 'semantic-ui-react';
import Appointments from './appointments/index'
import Profile from './profile/index'
import Login from './login'
import Payment from './bookingScreen/payment'
import VideoChatContainer from './videoControlers/VideoChatContainer';
import Strings from './strings.js'
import Background from './assets/landing_img.jpg'
class App extends Component {
  state = {}
  componentDidMount() {
    document.documentElement.style.setProperty('--themeColor', process.env.REACT_APP_PRIMARY_COLOR);
    document.documentElement.style.setProperty('--themeColorOpacity10', process.env.REACT_APP_PRIMARY_COLOR + 10);
    document.documentElement.style.setProperty('--themeColorOpacity30', process.env.REACT_APP_PRIMARY_COLOR + 30);
    let lang = localStorage.getItem('lang') || 'en'
    Strings.setLanguage(lang);
    document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr'

    let { providers, getSpecs, getInsurnces, getServices, getTopSpecs, getHelpers, getAmenities, getConditions } = this.props
    let specalities = providers.specalities || []
    let amenities = providers.amenities || []
    let conditions = providers.conditions || []
    let specKeys = []
    let insurncesKeys = []
    let amenitiesKes = []
    let conditionsKeys = []


    firestore().collection('clinics').where('deactivated', '!=', true).onSnapshot(async res => {
      let clinics = []
      res.forEach(record => {
        let data = record.data()
        let { selectedSpecialties, selectedInsurances, amenities, deactivated, bookingActive } = data
        if (deactivated) {
          return
        }
        specKeys = specKeys.concat(selectedSpecialties)
        amenitiesKes = amenitiesKes.concat(amenities)
        insurncesKeys = insurncesKeys.concat(Object.keys(selectedInsurances))
        clinics.push(record.data())
      })
      this.props.getFacilities(clinics)
      specKeys = [...new Set(specKeys)];
      for (var item in specKeys) {
        if (specKeys[item] && !specalities.find(spec => spec.id === specKeys[item])) {
          let rec = await this.getKeyName(specKeys[item], 'specialties')
          specalities.push(rec)
        }
      }
      getSpecs(specalities)

      amenitiesKes = [...new Set(amenitiesKes)]
      for (var item in amenitiesKes) {
        if (!amenities.find(amin => amin.id === amenitiesKes[item])) {
          let rec = await this.getKeyName(amenitiesKes[item], 'amenities')
          amenities.push(rec)
        }
      }
      getAmenities(amenities)
    })

    firestore().collection('doctors').onSnapshot(async res => {
      let doctors = []
      res.forEach(record => {
        let data = record.data()
        let { clinics, conditions, deactivated } = data || {}
        Object.keys(clinics || {}).map(key => {
          return specKeys = specKeys.concat(clinics[key])
        })
        conditionsKeys = conditionsKeys.concat(conditions)
        let activeClinics = {};

        let activeArray = Object.keys(clinics || {}).map(key => {
          if (deactivated) {
            if (!deactivated[key]) {
              activeClinics[key] = clinics[key]
              return true
            }
          } else {
            activeClinics[key] = clinics[key]
            return true
          }
          return false
        })
        if (activeArray.find(rec => !!rec)) {
          doctors.push({ ...data, id: record.id, clinics: activeClinics })
        }
      })
      this.props.getProviders(doctors)

      conditionsKeys = [...new Set(conditionsKeys)];
      for (var item in conditionsKeys) {
        if (!conditions.find(spec => spec.id === conditionsKeys[item])) {
          let rec = await this.getKeyName(conditionsKeys[item], 'conditions')
          conditions.push(rec)
        }
      }
      getConditions(conditions)

      specKeys = [...new Set(specKeys)];
      for (var item in specKeys) {
        if (specKeys[item] && !specalities.find(spec => spec.id === specKeys[item])) {
          let rec = await this.getKeyName(specKeys[item], 'specialties')
          specalities.push(rec)
        }
      }
      getSpecs(specalities)

    })
    firestore().collection('insurances').get().then((res) => {
      let insurnces = []
      res.forEach(rec => {
        insurnces.push({ ...rec.data(), id: rec.id })
      })
      getInsurnces(insurnces)

    })

    firestore().collection('services').get().then((res) => {
      let allServices = []
      res.forEach(rec => {
        allServices.push({ ...rec.data(), id: rec.id })
      })
      getServices(allServices)
    })

    firestore().collection('helpers').get().then((res) => {
      let helpers = {}
      res.forEach(rec => {
        Object.keys(rec.data()).map(key => {
          if (rec.id === 'topSpecialities') {
            let topSpecKeys = rec.data()?.list
            let topSpecData = [];
            topSpecKeys.forEach(item => {
              let specilaity = specalities.find(spec => spec.id === item)
              if (!specilaity) {
                this.getKeyName(item, 'specialties').then((rec) => {
                  specalities.push(rec)
                  topSpecData.push(rec)
                  getSpecs(specalities)
                  getTopSpecs(topSpecData)
                })
              } else {
                topSpecData.push(specilaity)
                getTopSpecs(topSpecData)
              }
            })
          }
          return helpers[key] = rec.data()[key].filter(r => !!r)
        })
      })
      getHelpers(helpers)
    })
    auth().onAuthStateChanged(async user => {
      if (user) {
        let token;
        try {
          token = await messaging().getToken('BDTLF-Hc7OxiWx3j76UdQQyvSO50htxiNFtf8MFPwyxeJ2inlm0UNsicFOniJTvOBhfsjNi4VeObXLFzcgdzix8');
          if (!token) {
            let permission = await Notification.requestPermission();
            if (permission === 'granted') {
              token = await messaging().getToken('BDTLF-Hc7OxiWx3j76UdQQyvSO50htxiNFtf8MFPwyxeJ2inlm0UNsicFOniJTvOBhfsjNi4VeObXLFzcgdzix8');
            }
          }
          console.log("tokentokentokentoken", token);
          await firestore().collection('users').doc(user.uid).set({
            FCMtokens: token
          }, { merge: true })
        } catch (error) {
          console.log("errorerrorerror", error);
        }
        firestore().collection('users').doc(user.uid).onSnapshot(async snap => {

          if (snap.exists && snap.data().firstName) {
            firestore().collection('bookings').where('patient', '==', user.uid).onSnapshot(bookings => {
              let userBookings = []
              bookings.forEach(async rec => {
                let data = rec.data()
                userBookings.push({ ...data, id: rec.id })
              })
              this.props.getUser({ ...snap.data(), uid: user.uid, userBookings })
            })
          }
        })
      }
      else {
        this.props.getUser(false)
        // if (window.location.pathname !== '/login') {
        //   window.location.href = '/login'
        // }
      }
    })

  }
  getKeyName(key, flag) {
    return new Promise((resolve, reject) => {
      firestore().collection(flag).doc(key).get().then(res => {
        resolve({ ...res.data(), id: res.id })
      })
    })
  }
  render() {
    let { search } = this.state
    let { providers } = this.props
    if (!providers || !providers.providers || !providers.facilities || !providers.services || !providers.insurnces || !providers.topSpecalties) {
      return (
        <Dimmer active={true}>

          <Loader active={true} />
        </Dimmer>
      )
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="App" style={{ display: 'flex', flexDirection: 'column',height:'120vh', justifyContent: 'space-between',zIndex:99 ,background:'#fff'}}>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/pay' component={Payment} />
            <Route path="/call" component={VideoChatContainer} />
            <Route path='/book' component={Booking} />
            <Route path='/profile' component={Profile} />
            <Route path='/appointments' component={Appointments} />
            <Route path='/doctor-profile' component={DoctorProfile} />
            <Route path='/facility-profile' component={FacilityProfile} />
            <Route path='/searchView' component={SearchView} />

            <Route path='/' render={() => {
              return (<Home search={search} />)
            }} />

          </Switch>

          {/* <Footer /> */}

        </div>
        <div
          className='emptySpace'
          style={{
            backgroundImage: `url("${Background}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }} >
          {/* <div style={{
          height: '100%',
          width: '100%',
          // backgroundColor: 'rgb(254 254 254 / 95%)',
        }} /> */}
        </div>


        {/* <div id='rightSide' className='rightSide' style={{ backgroundImage: `url(${Background})` }}></div> */}
      </div>
    );
  }
}
const mapStateToProps = ({ providers, user, history, helpers }) => {

  return {
    providers,
    user,
    history

  };
};
export default connect(mapStateToProps, { getProviders, getFacilities, getSpecs, getInsurnces, getServices, getTopSpecs, getHelpers, getUser, getAmenities, getConditions })(App);