import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { firestore } from '../fire';
import ReviewModal from '../appointments/reviewModal'
import Strings from '../strings';
import { getName } from '../helpers';
class Reviews extends Component {
    state = {
        reviews: []
    }
    componentDidMount() {
        let { user, doctors } = this.props


        firestore().collection('reviews').where('patientId', '==', user?.uid).get().then(res => {
            let array = []

            res.forEach(record => {
                let score = record.data()?.score
                let totalScore = 0
                Object.values(score).map(rec => {
                    totalScore = totalScore + (rec * 1)
                })
                let doctor = doctors.find(rec => rec.id === record.data().doctorId)
                array.push({ ...record.data(), id: record.id, doctor: doctor, totalScore: totalScore / 4 })
            })
            array.sort((a, b) => b.created - a.created)
            this.setState({ reviews: array })
        })
    }
    render() {
        let { user, doctors } = this.props
        let { reviews, recommend, review } = this.state
        return (
            <div className='reviews'>
                <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '22px' }}>{Strings.Feedback}</div>
                <div style={{ padding: '8px 0' }}>
                    {
                        reviews?.length ? reviews.map(item => {
                            return (
                                <div key={item.id} className='review_card'>
                                    <div style={{
                                        display: 'flex',
                                        padding: '8px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cacaca30'
                                    }}>
                                        <img style={{ width: '50px', height: '50px' }} src={item?.doctor?.profilePicture} />
                                        <div style={{ fontFamily: 'tfMedium', fontSize: '16px', padding: '8px', width: '80%' }}>{getName(item?.doctor, "name")}</div>
                                        <div style={{ padding: '4px 8px', backgroundColor: '#cacaca', display: 'flex', borderRadius: '8px' }}>
                                            {item?.totalScore}
                                            <Icon name='star' />
                                        </div>
                                    </div>
                                    {
                                        item.comment ?
                                            <div style={{ color: '#cacaca', padding: '4px 0' }}>{item.comment}</div>
                                            :
                                            <div
                                                onClick={() => {
                                                    let activeBooking = user?.userBookings?.find(rec => rec.id === item.bookingId)
                                                    console.log("activeBooking", activeBooking)
                                                    this.setState({ recommend: activeBooking, review: item })
                                                }}
                                                style={{ color: 'var(--themeColor)', padding: '4px 0', cursor: 'pointer' }}
                                            > {Strings['Write a review']}</div>
                                    }
                                </div>
                            )
                        })
                        :
                        <div style={{ paddingTop: '50%', display: 'flex', justifyContent: 'center', fontFamily: 'tfBold', fontSize: '18px' }}> {Strings['No Feedbacks yet!']}</div>

                    }

                </div>
                {
                    recommend && <ReviewModal
                        review={review}
                        recommend={recommend}
                        providers={doctors}
                        user={user}
                        dismiss={() => this.setState({ recommend: false })} />
                }
            </div>
        );
    }
}

export default Reviews;