import React, { Component } from 'react';
import './nearby.css'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Link } from 'react-router-dom'
import getDistance from 'geolib/es/getDistance';
import { checkOpenNow, getClinicDistance, getName } from '../../helpers'
import Strings from '../../strings'
class Nearby extends Component {


    render() {
        let { facilities, userLocation } = this.props
        let array = facilities.sort((a, b) => {
            if (a?.location && b?.location && userLocation) {
                return getDistance(a.location, userLocation) - getDistance(b.location, userLocation)
            }
            return -1
        })
        array = array?.slice(0, 7)
        return (
            <div className='nearby'>
                <div className='head'>
                    <div className='title'>{process.env.REACT_APP_NAME !== 'saeed' ?
                        Strings.nearbyClinics : Strings.locations}</div>
                    <Link to='/searchView?search_type=facilities' className='showAll'>{Strings.showAll}</Link>
                </div>
                <ScrollContainer className='scrollView' >
                    {array.map((item) => {
                        let open = checkOpenNow(item)
                        return (
                            <Link to={'/facility-profile?key=' + item.facilityId} className='card' key={item.facilityId} style={{ minWidth: array.length === 1 ? '100%' : '90%' }}>
                                <div className='cardHead' >
                                    <div className='name'>{getName(item, "facilityName")}</div>
                                    <div className='open' style={open !== 'Open Now' ? { color: 'red' } : { color: '#77C344' }}>{
                                        Strings[open]
                                    }</div>
                                </div>
                                <div className='cardContent' style={{ backgroundImage: 'url( ' + (item.pictures?.length > 0 ? item.pictures[0] : '') + ' )' }}>
                                    <div className='background' >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', width: '100%', alignItems: 'flex-end' }}>
                                            <img className='logo' src={item?.logoUrl} />
                                            {
                                                userLocation ?
                                                    <div style={{ color: '#fff' }} >{getClinicDistance(item, userLocation)}{Strings.km}</div>
                                                    : ''
                                            }
                                        </div>
                                    </div>

                                </div>

                            </Link>
                        )
                    })}
                </ScrollContainer>
            </div>
        );
    }
}

export default Nearby;