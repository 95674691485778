import React, { Component } from 'react';
import { getName } from '../../helpers';
import Strings from '../../strings';
function getCords(facility) {
    let { location } = facility
    return `${location?.latitude},${location?.longitude}`

}
class Timmings extends Component {
    state = {
        collapse: true,
        week: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

    }

    render() {
        let { facility } = this.props
        const url = `https://maps.google.com?daddr=${getCords(facility)}`;

        let { collapse, week } = this.state
        let today = week[(new Date()).getDay()]
        let { openingDays } = facility
        let weekDays = [
            { key: "SAT", date: "Saturday" },
            { key: "SUN", date: "Sunday" },
            { key: "MON", date: "Monday" },
            { key: "TUE", date: "Tuesday" },
            { key: "WED", date: "Wednesday" },
            { key: "THU", date: "Thuresday" },
            { key: "FRI", date: 'Friday' }];
        let array = weekDays.map(day => {
            let availbleTimings = openingDays[day.key]
            if (availbleTimings.opened) {
                if (availbleTimings.isTwentyFour) {
                    return { label: day.date, key: day.key, value: Strings['open 24h'] }
                }
                if (availbleTimings.isSplitShift) {
                    return { label: day.date, key: day.key, value: `${Strings.from} ${availbleTimings.fromTime} ${Strings.to} ${availbleTimings.toTime} \n ${Strings.from} ${availbleTimings.pmFromTime} ${Strings.to} ${availbleTimings.pmToTime}` }
                }
                return { label: day.date, key: day.key, value: `${Strings.from} ${availbleTimings.fromTime} ${Strings.to} ${availbleTimings.toTime}` }
            }
            return { label: day.date, key: day.key, value: Strings.Closed }

        })
        if (collapse) {
            array = [array.find(rec => rec.key === today)]
        }
        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings.Timings
                    }

                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: collapse ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
                        <div>
                            {
                                array.map(item => {
                                    return (
                                        <div key={item.label} style={{ padding: '4px 0' }}>
                                            <div style={{ fontSize: '16px', color: 'var(--themeColor)', padding: '2px 0' }}>{Strings[item.label]}</div>
                                            <div>{item.value}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ width: '35%' }} onClick={() => {
                            window.open(url, '_blank')
                        }}>
                            <div style={{ textAlign: 'center', padding: '8px' }}>
                                {
                                    [getName(facility, "address")?.area, getName(facility, "address")?.city].join(' - ')
                                }
                            </div>
                            <img
                                style={{
                                    width: '100%',
                                    height: '90px',
                                    backgroundColor: '#cacaca',
                                    borderRadius: '4px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url("https://maps.googleapis.com/maps/api/staticmap?center=${facility.location.latitude},${facility.location.longitude}&zoom=14&size=165x90&key=AIzaSyCb34POFDf2Ww36X42ntRQLFvZdg-_73Jg")`
                                }}
                            />
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', padding: '12px 0 0' }} onClick={() => this.setState({ collapse: !collapse })}>
                        {collapse ? Strings.showAll : Strings.today}
                    </div>
                </div>


            </div >
        );
    }
}

export default Timmings;