import React, { Component } from "react";
import { GoSellElements } from "@tap-payments/gosell";
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import { firestore, functions } from '../fire'
import moment from "moment";
import { connect } from "react-redux";
class GoSellDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    authorize = async (savedCard, customer, bookingId, userUID) => {
        let authResponse = await functions().httpsCallable('createToken')({
            saved_card: {
                card_id: savedCard.id,
                customer_id: savedCard.customer
            },
            bookingId: bookingId,
            customer: customer,
            userUid: userUID,
            redirect: `${window.location.origin}/pay`
        })

        if (authResponse) {
            let { data } = authResponse
            let { transaction, id, status } = data
            if ((status === "INITIATED") && transaction) {
                window.location.href = transaction.url
            } else if (status === "AUTHORIZED") {
                if (window.ReactNativeWebView) {
                    return window.ReactNativeWebView.postMessage(JSON.stringify(data))
                }
                if (window.webkit) {
                    return window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ response: data }));
                }
                if (window.Android) {
                    return window.Android.showMessageInNative(JSON.stringify({ response: data }))
                }

                this.setState({ loading: false }, () => {
                    this.props.history.push("/appointments");
                })

            } else {
                this.setState({ error: "Cannot process your card" }, () => {
                    setTimeout(() => {
                        this.setState({ error: false })
                    }, 5000)
                })
            }
        }
    }
    callbackFunc = (response) => {
        let url = new URL(window.location.href)
        let bookingId = url.searchParams.get('booking_id')
        let userUID = url.searchParams.get('user')

        this.setState({ loading: true })
        if (response) {
            if (response && response.id) {
                let createCustomer = functions().httpsCallable('createCustomer')
                createCustomer({
                    token: response.id,
                    userUID: userUID
                }).then(async (customerResponse) => {
                    if (customerResponse && customerResponse.data && customerResponse.data.savedCard && customerResponse.data.customer) {
                        let { savedCard, customer } = customerResponse.data
                        this.authorize(savedCard, customer, bookingId, userUID)
                    } else {
                        console.log('customerResponse', customerResponse)
                        this.setState({ error: customerResponse.data.error, loading: false }, () => {
                            setTimeout(() => {
                                this.setState({ error: false })
                            }, 5000)
                        })
                    }
                }).catch((error) => {
                    this.setState({ error: error, loading: false }, () => {
                        setTimeout(() => {
                            this.setState({ error: false })
                        }, 5000)
                    })
                })
            }
        }

    }
    componentWillMount() {
        let url = new URL(window.location.href)
        let authId = url.searchParams.get('tap_id')
        if (authId) {
            this.setState({ loading: true })
            functions().httpsCallable('checkAuthStatus')({
                authId: authId
            }).then((results) => {
                let { data } = results
                let { status } = JSON.parse(data)
                if (status === "AUTHORIZED") {
                    this.setState({ loading: false }, () => {
                        if (window.ReactNativeWebView) {
                            return window.ReactNativeWebView.postMessage(JSON.stringify({ authId: authId }))
                        }
                        if (window.webkit) {
                            return window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ authId: authId }));
                        }
                        if (window.Android) {
                            return window.Android.showMessageInNative(JSON.stringify({ authId: authId }))
                        }
                        return this.props.history.push("/appointments");
                    })
                } else {
                    this.setState({
                        error: "Cannot process your card",
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ error: false })
                        }, 5000)
                    })
                }
            })
        } else {
            let selectedCardId = url.searchParams.get('card')
            let bookingId = url.searchParams.get('booking_id')
            let userUID = url.searchParams.get('user')
            if (selectedCardId) {
                this.setState({ loading: true }, () => {
                    let { user } = this.props
                    let { userCards } = user
                    let selectedCard = userCards[selectedCardId]
                    if (selectedCard) {
                        this.authorize(selectedCard.savedCard, selectedCard.customer, bookingId, userUID)
                    }
                })
            }
        }
    }
    cancelTapped() {
        let url = new URL(window.location.href)
        let bookingId = url.searchParams.get('booking_id')
        firestore().collection('authorizing').doc(bookingId).get().then((res) => {
            let data = res.data()
            if (data) {
                let { duration, dateTS, doctor, facilityId } = data
                let start = moment(dateTS)
                let end = moment(dateTS).add(duration || 15, 'minute')
                let deletionSlots = []
                while (start < end) {
                    deletionSlots.push(start.format('hh:mm A'))
                    start.add(15, 'minute')
                }

                firestore().collection('slots').doc(doctor).collection(facilityId).doc(moment(dateTS).format('MMM DD, yyyy')).set({
                    [moment(dateTS).format('MMM DD, yyyy')]: firestore.FieldValue.arrayRemove(...deletionSlots)
                }, { merge: true }).then(() => {
                    firestore().collection('authorizing').doc(bookingId).delete()
                    window.history.back()
                })
            }
        })
    }

    render() {
        let { direction } = this.props
        let { loading, error } = this.state
        return (
            <div>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    <div style={{ width: '20%', color: '#fff', fontFamily: 'tfMedium' }} onClick={() => this.cancelTapped()}>Cancel</div>
                    <div style={{ color: '#fff', textAlign: 'center', width: '100%', fontFamily: 'tfBold', fontSize: '16px' }}>Payment</div>
                    <div style={{ width: '20%' }} />
                </div>
                {
                    loading &&
                    <Dimmer active={true} page>
                        <Loader>Processing Payment</Loader>
                    </Dimmer>
                }

                <div style={{ padding: '16px 0' }}>


                    <GoSellElements
                        gateway={{
                            publicKey: process.env.REACT_APP_TAP_KEY,
                            language: "en",
                            supportedCurrencies: "all",
                            supportedPaymentMethods: "all",
                            notifications: 'msg',
                            saveCardOption: true,
                            callback: (r) => this.callbackFunc(r),
                            labels: {
                                cardNumber: "Card Number",
                                expirationDate: "MM/YY",
                                cvv: "CVV",
                                cardHolder: "Name on Card",
                                actionButton: "Pay"
                            },
                            style: {
                                base: {
                                    color: '#535353',
                                    lineHeight: '18px',
                                    fontFamily: 'sans-serif',
                                    fontSmoothing: 'antialiased',
                                    fontSize: '16px',
                                    margin: '8px',
                                    '::placeholder': {
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        fontSize: '15px'
                                    }
                                },
                                invalid: {
                                    color: 'red',
                                    iconColor: '#fa755a '
                                }
                            }
                        }}
                    />

                    <p id="msg"></p>
                </div>


                <div className='cartDetail' style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='actionBtn'>
                        <Button color='blue' style={{ width: '200px' }} onClick={() => GoSellElements.submit()}>
                            {
                                direction === 'rtl' ?
                                    'ادفع'
                                    :
                                    'Pay'
                            }

                        </Button>
                    </div>

                </div>
                {
                    error && <div style={{ color: 'red', textAlign: 'center', width: '100%', padding: '16px', fontSize: '22px' }}>{error} </div>
                }
            </div>
        );
    }
}
const mapStateToProps = ({ user }) => {
    console.log('user', user)
    return { user: user?.user }
}

export default connect(mapStateToProps, {})(GoSellDemo);
