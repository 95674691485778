import React, { Component } from 'react';
import RecentViewdCard from '../home/recentViewd/recentViewdCard'
import FacilityCard from './facilityCard'
import ScrollContainer from 'react-indiana-drag-scroll'
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react'
import './index.css'
import { Link } from 'react-router-dom'
import Popup from './popup'
import { checkOpenNow } from '../helpers'
import Strings from '../strings';
function arrayCompare(_arr1, _arr2) {
    if (
        !Array.isArray(_arr1)
        || !Array.isArray(_arr2)
    ) {
        return false;
    }
    let flag = false
    _arr1.forEach(rec => {
        if (_arr2.includes(rec)) {
            flag = true
        }
    })
    return flag
}
function applyFilters(array, filters, user) {
    if (filters.gender?.length) {
        array = array.filter(rec => rec.gender?.toLowerCase() === filters?.gender)
    }
    if (filters.nationality?.length) {
        array = array.filter(rec => rec.nationalities?.includes(...filters.nationality))
    }
    if (filters.lang?.length) {
        array = array.filter(rec => rec.languages?.includes(...filters.lang))
    }
    if (filters.open) {
        array = array.filter(rec => checkOpenNow(rec) === 'Open Now')
    }
    if (filters.myInsurnce) {
        let userInsurnces = user?.insurances?.map(rec => rec?.insurance);
        array = array.filter(rec => {
            return arrayCompare(userInsurnces, Object.keys(rec.selectedInsurances))
        }
        )
    }
    if (filters.facilityKey) {
        let { doctors } = filters?.activeFacility || {}
        if (doctors) {
            array = array.filter(rec => doctors.includes(rec.id) && Object.keys(rec.clinics).includes(filters.facilityKey))
        }
    }
    return array
}
class index extends Component {
    state = {
        flag: (new URL(window.location.href)).searchParams.get('search_type') || 'facilities',
        speciality: (new URL(window.location.href)).searchParams.get('speciality') || false,
        facilityKey: (new URL(window.location.href)).searchParams.get('facility') || false,

    }
    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (this.state.facilityKey) {
            let { providers } = this.props
            let activeFacility = providers.facilities?.find(rec => rec.facilityId === this.state.facilityKey)
            if (activeFacility) {
                this.setState({ activeFacility })
            }
        }
    }

    render() {
        let { flag, speciality, filter, facilityKey } = this.state
        let { user } = this.props

        let filters = flag === 'facilities' ?
            [{ key: 'open', value: 'Open Now' }, { key: 'myInsurnce', value: 'My insurance' }] :
            [{ key: 'lang', value: 'Languages' }, { key: 'nationality', value: 'Nationalities' }, { key: 'gender', value: 'Gender' }]
        let { providers } = this.props
        let array = flag === 'facilities' ? providers?.facilities : providers?.providers;
        array = applyFilters(array, this.state, user)

        let lang = localStorage.getItem('lang')
        if (speciality) {
            switch (flag) {
                case "facilities":
                    array = array.filter(rec => rec.selectedSpecialties.includes(speciality));
                    filters = [{ key: 'type', value: 'Facilities' }, { key: 'open', value: 'Open Now' }, { key: 'myInsurnce', value: 'My insurance' }];
                    break
                default:
                    array = array.filter(rec => {
                        let test = Object.keys(rec.clinics).map(key => {
                            return rec.clinics[key].includes(speciality)
                        })
                        return test.includes(true)
                    });
                    filters = [{ key: 'type', value: 'Doctors' }, { key: 'lang', value: 'Languages' }, { key: 'nationality', value: 'Nationalities' }, { key: 'gender', value: 'Gender' }]
                    break
            }
        }


        return (
            <div className='search-view'>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    {
                        facilityKey ?
                            <div onClick={() => window.history.back()} style={{ color: '#fff' }} >
                                <Icon style={{ fontSize: '22px' }} name={lang === 'ar' ? 'arrow right' : 'arrow left'}></Icon>
                            </div>
                            :
                            <Link to='/' style={{ color: '#fff' }} >
                                <Icon style={{ fontSize: '22px' }} name={lang === 'ar' ? 'arrow right' : 'arrow left'}></Icon>
                            </Link>
                    }

                </div>
                {
                    facilityKey ? '' :
                        <ScrollContainer style={{ display: 'flex' }}>
                            {
                                filters.map(item => {
                                    return (
                                        <div className='search-tags'
                                            style={this.state[item.key] || item.key === 'type' ? { backgroundColor: 'var(--themeColor)', color: '#fff' } : { backgroundColor: '#fff', color: 'var(--themeColor)' }}
                                            key={item.key}
                                            onClick={() =>
                                                ['open', 'myInsurnce'].includes(item.key) ?
                                                    this.setState({ [item.key]: !this.state[item.key] })
                                                    : this.setState({ filter: item })}>
                                            <div style={{ fontFamily: 'tfMedium', padding: '0 8px' }}>{Strings[item.value]}</div>
                                        </div>)
                                })

                            }
                        </ScrollContainer>
                }

                <div style={{ padding: '0 8px' }}>
                    {
                        array?.length > 0 ? array.map(item => {
                            return (
                                <div
                                    key={item.facilityId || item.id}
                                    style={{ padding: '8px 0' }}>
                                    {
                                        flag === 'facilities' ?
                                            <FacilityCard clinic={item} />
                                            : <RecentViewdCard doctor={item} specalities={providers?.specalities} facilities={providers?.facilities} selectedSpec={speciality} />

                                    }

                                </div>
                            )

                        })
                            : <div style={{ paddingTop: '80%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ maxWidth: '90%' }}>
                                    <div> we are sorry that you couldn't find your <span style={{ fontFamily: 'tfBold' }}>Doctor!</span></div>
                                    <div>stay tuned,As we are continuously adding new Doctors and Clinics</div>
                                </div>


                            </div>
                    }
                </div>
                {
                    filter && <Popup
                        switchFlag={(flag) => {
                            this.setState({
                                flag: flag,
                                filter: false,
                                lang: false,
                                nationality: false,
                                gender: false,
                                openNow: false,
                                myInsurnce: false

                            });
                            var queryParams = new URLSearchParams(window.location.search);
                            queryParams.set("search_type", flag);
                            window.history.replaceState(null, null, "?" + queryParams.toString());
                        }}
                        selectedFilters={this.state[filter.key] || []}
                        filter={{ title: filter.value, key: filter.key }}
                        helpers={this.props.helpers}
                        updateFilter={(e) => this.setState(e)} />
                }
            </div>
        );
    }
}
const mapStateToProps = ({ providers, helpers, user }) => {
    console.log("useruser", user)
    return {
        providers,
        helpers: helpers?.helpers,
        user: user?.user

    };
};
export default connect(mapStateToProps, {})(index);
