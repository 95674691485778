import React, { Component } from 'react';
import { getName } from '../../helpers';
import Strings from '../../strings';

class Speciality extends Component {
    render() {
        let { doctorSpec } = this.props
        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings.Specialty
                    }
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ color: 'var(--themeColor)', fontSize: '16px', fontFamily: 'tfMedium' }}>
                        {getName(doctorSpec, "name")}
                    </div>
                    <div style={{ fontSize: '13px', fontFamily: 'tfRegular', fontStyle: 'italic' }}>
                        {getName(doctorSpec, "definition")}
                    </div>
                </div>

            </div>
        );
    }
}

export default Speciality;