import React, { Component } from 'react';
import Header from './header/header'
import About from './about/about'
import General from './general/general'
import Clinics from './clinics/clinics'
import Profession from './professional/professional'
import Specialty from './speciality/speciality'
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react';
import TagView from '../facilityProfile/tagView/tagView'
import './index.css'
import { getProviders } from '../reducers/providers'
import { firestore, auth } from '../fire';
import Strings from '../strings';
import { withRouter } from 'react-router-dom'
var lastScrollTop = 0;

class index extends Component {
    state = {}
    componentDidMount() {
        let doctorId = new URL(window.location.href).searchParams.get('key')
        let recentDoctors = localStorage.getItem("recentDoctors")?.split(',') || [];
        recentDoctors = recentDoctors?.filter(rec => (rec !== doctorId) && (rec !== ""))
        recentDoctors?.unshift(doctorId)
        localStorage.setItem('recentDoctors', recentDoctors.slice(0, 7)?.join(','))
        // let { providers, getProviders } = this.props

        if (doctorId) {
            firestore().collection('doctors').doc(doctorId).update({
                views: firestore.FieldValue.increment(1)
            }).then(() => {
                // let newProviders = providers?.providers.map(rec => {
                //     if (rec.id === doctorId) {
                //         rec.views = rec.views + 1
                //         return rec
                //     } else {
                //         return rec
                //     }
                // })
                // getProviders(newProviders)
            })
        }
        window.addEventListener("scroll", this.detectScroll, false);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    detectScroll() {
        let element = document.getElementById('doctor_actions')
        const bottom = ((document.body.scrollHeight - 200) <= (document.body.clientHeight + lastScrollTop) && lastScrollTop > 50)

        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (((st > lastScrollTop) || bottom) && element) {
            // downscroll code
            element.style.display = 'none'
        } else {
            element.style.display = 'flex'
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.detectScroll)

    }
    render() {
        let { announce } = this.state
        let { providers } = this.props
        let specalities = providers.specalities ? providers.specalities : [];
        let doctorId = new URL(window.location.href).searchParams.get('key')
        let doctor = providers.providers.find(item => item.id === doctorId)
        let { clinics } = doctor
        let doctorSpec = Object.values(clinics).map(specKeys => {
            let spec = specalities.find(item => specKeys.includes(item.id))
            return spec
        })
        let allFacilities = providers.facilities || []
        let activeClinics = Object.keys(clinics).map(clinicKey => {
            let clinic = allFacilities.find(item => item.facilityId === clinicKey)
            if (clinic) {
                return {
                    ...clinic,
                    bookingActive: Object.keys(doctor.bookingActive || {}).includes(clinicKey)
                }
            }


        })
        activeClinics = activeClinics.filter((x) => x !== undefined);

        let doctorConditions = doctor?.conditions?.map(item => {
            return providers?.conditions.find(rec => rec.id === item)
        })
        doctorConditions = doctorConditions?.filter(x => x !== undefined)
        let lang = localStorage.getItem('lang')

        return (
            <div className='facility'>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    <div style={{ color: '#fff' }} onClick={() => window.history.back()}>
                        <Icon style={{ fontSize: '22px' }} name={lang === 'ar' ? 'arrow right' : 'arrow left'}></Icon>
                    </div>
                </div>
                <Header doctor={doctor} doctorSpec={doctorSpec} />
                {
                    doctor.shortBio ?
                        <About doctor={doctor} />
                        : ''

                }
                <General doctor={doctor} />
                {
                    doctorConditions && doctorConditions.length ? <TagView title='Can help with' tags={doctorConditions} /> : ''
                }
                {
                    activeClinics && activeClinics.length ? <Clinics doctor={doctor} activeClinics={activeClinics} /> : ''
                }

                <Profession doctor={doctor} />
                {
                    doctorSpec?.length ? <Specialty doctorSpec={doctorSpec[0]} /> : ''
                }
                {
                    announce ? <div className='announce'>
                        <div style={{ backgroundColor: '#00000060', padding: '20px', borderRadius: '8px', fontFamily: 'tfMedium', fontSize: '18px', color: '#fff' }}>
                            {Strings["Link Copied to your clipboard!"]}
                        </div>
                    </div>
                        : ''
                }
                {
                    this.footer(doctor)
                }


            </div>
        );
    }
    footer(doctor) {
        let userUID = auth().currentUser?.uid
        let liked = false
        let recommended = false
        if (userUID) {
            liked = doctor?.favorite?.stars && Object.keys(doctor?.favorite?.stars).includes(userUID)
            recommended = doctor?.recommend?.stars && Object.keys(doctor?.recommend?.stars).includes(userUID)
        }
        return (
            <div id='doctor_actions' className='doctor_actions'>
                <div className='btn' onClick={() => {
                    navigator.clipboard.writeText(window.location.href)

                    this.setState({ announce: true }, () => {
                        setTimeout(() => {
                            this.setState({ announce: false })
                        }, 3000)
                    })
                }}>
                    <Icon className='btn' style={{ color: 'gray' }} name='share square outline' />
                </div>
                <div className='btn'>
                    <Icon onClick={() => this.like(liked)} className='btn' style={{ color: 'red' }} name={liked ? 'heart' : 'heart outline'} />
                </div>
                <div className='btn' onClick={() => this.recommend(recommended)} style={{ backgroundColor: recommended ? 'var(--themeColor)' : '#fff', color: recommended ? '#fff' : 'var(--themeColor)', borderRadius: '22px', width: '140px' }}>
                    <div style={{ fontSize: '18px', fontFamily: 'tfBold' }}>{Strings.recommend}</div>
                </div>
            </div>
        )

    }
    like(flag) {
        let doctorId = new URL(window.location.href).searchParams.get('key')
        if (!auth().currentUser) {
            return this.props.history.push('/login')
        }
        firestore().collection('doctors').doc(doctorId).update({
            'favorite.starCount': firestore.FieldValue.increment(flag ? -1 : 1),
            [`favorite.stars.${auth().currentUser.uid}`]: flag ? firestore.FieldValue.delete() : true
        })
    }
    recommend(flag) {
        let doctorId = new URL(window.location.href).searchParams.get('key')
        if (!auth().currentUser) {
            return this.props.history.push('/login')
        }
        firestore().collection('doctors').doc(doctorId).update({
            'recommend.starCount': firestore.FieldValue.increment(flag ? -1 : 1),
            [`recommend.stars.${auth().currentUser.uid}`]: flag ? firestore.FieldValue.delete() : true
        })
    }
}
const mapStateToProps = ({ providers }) => {
    return {
        providers,
    };
};
export default connect(mapStateToProps, { getProviders })(withRouter(index));
