import { createAction } from 'redux-action';
export const GET_USER = 'GET_USER';
export const getUser = createAction(GET_USER, (list) => {
    return list
});
const initial = {};

let ex2 = (state = initial, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
};
export default ex2