import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import Strings from '../../strings';

class General extends Component {
    render() {
        let { doctor } = this.props
        let array = [{ label: "language spoken", icon: 'stack exchange', value: doctor?.languages?.join(', ') },
        { label: "Nationality", icon: 'globe', value: doctor?.nationalities?.join(', ') },
        { label: "Gender", icon: 'transgender', value: doctor?.gender },
        ]
        return (
            <div className='section'>
                <div className='sectionHead'>
                    {
                        Strings.general
                    }
                </div>
                <div>
                    {
                        array.map(item => {
                            return (
                                <div key={item.label} style={{ display: 'flex', padding: '4px 0' }}>
                                    <Icon style={{ margin: '2px 6px 0 0', color: 'var(--themeColor)' }} name={item.icon} />
                                    <div>
                                        <div style={{ fontSize: '14px', color: '#4d4d4d' }}>
                                            {Strings[item.label]}
                                        </div>
                                        <div style={{ color: 'var(--themeColor)', fontSize: '16px' }}>{item.value}</div>
                                    </div>
                                </div>
                            )
                        })
                    }



                </div>
            </div>
        );
    }
}

export default General;