import React, { Component } from 'react';
import { Icon, Input, Modal } from 'semantic-ui-react';
import { auth, firestore } from '../fire';
import { getName } from '../helpers';
import Strings from '../strings';

class Vouchers extends Component {
    state = {
        vouchers: []
    }
    getVouchers = async (user) => {
        let { facilities } = this.props
        let array = []
        let vouchers = await firestore().collection('promoCode').where('userUID', 'array-contains', user?.uid).get()
        vouchers.forEach(record => {
            let clinic = facilities.find(rec => rec.facilityId === record.data()?.clinicId)
            array.push({ ...record.data(), id: record.id, clinic: clinic })
        })
        this.setState({ vouchers: array, loading: false })
    }
    componentDidMount() {
        let { user } = this.props
        this.getVouchers(user)
    }
    checkCode = () => {
        let { code } = this.state
        let { user } = this.props

        if (!code) {
            return alert('Please enter valid Promo code!')
        }
        firestore().collection('promoCode').doc(code.toLowerCase()).get().then(async (res) => {
            if (res.exists) {
                let data = res.data()
                if (data?.userUID.includes(user.uid)) {
                    return alert(Strings['This voucher is already redeemed!'])
                }
                if (data.count === 0) {
                    return alert(Strings['This voucher expired!'])
                } else {
                    await firestore().collection('users').doc(user.uid).update({
                        voucher: firestore.FieldValue.arrayUnion(code.toLowerCase())
                    })
                    await firestore().collection('promoCode').doc(code.toLowerCase()).update({
                        count: firestore.FieldValue.increment(-1),
                        userUID: firestore.FieldValue.arrayUnion(user.uid)
                    })
                    this.getVouchers(user)
                    alert(Strings['Voucher is successfuly added to your profile'])
                }
            } else {
                return alert(Strings['Please enter valid Promo code!'])
            }
        })
    }
    redeem(item) {
        let { user } = this.props
        firestore().collection('promoCode').doc(item.id).update({
            redeemedUsers: firestore.FieldValue.arrayUnion(user.uid)
        }).then(() => {
            this.getVouchers(user).then(() => {
                this.setState({ redeem: false })
            })
        })

    }
    render() {
        let { user } = this.props
        let { vouchers, redeem } = this.state
        return (
            <div className='voucher'>
                <div className='promobox' >
                    <Input
                        transparent
                        onChange={(e, { value }) => this.setState({ code: value })} style={{ width: '80%' }}
                        placeholder={Strings['Promo code here']} />
                    <div style={{
                        width: '20%',
                        textAlign: 'center',
                        padding: '8px',
                        color: '#fff',
                        backgroundColor: 'var(--themeColor)',
                        borderRadius: '8px',
                        fontFamily: 'tfMedium',
                        fontSize: '18px',
                        cursor: 'pointer'
                    }}
                        onClick={() => this.checkCode()}
                    >{Strings.Get} </div>
                </div>
                {
                    vouchers.map(rec => {
                        let redeemed = rec.redeemedUsers.includes(user.uid)
                        return (
                            <div
                                key={rec.id}
                                className='cell'
                                style={{ opacity: redeemed ? '0.3' : '1' }}
                                onClick={() => {
                                    if (redeemed) {
                                        return
                                    }
                                    this.setState({ redeem: rec })
                                }}
                            >
                                <img className='logo' src={rec?.clinic?.logoUrl} />
                                <div style={{ padding: '8px', width: '75%' }}>
                                    <div style={{ fontSize: '16px', fontFamily: 'tfMedium' }}>{rec?.description}</div>
                                    <div style={{ fontFamily: 'tfRegular' }}>{rec?.speciality}</div>
                                </div>
                                {
                                    redeemed && <div style={{ fontSize: '10px', fontFamily: 'tfThin' }}>{Strings.Redeemed}</div>
                                }
                            </div>
                        )
                    })
                }
                {
                    redeem && this.renderConfirmRedeem(redeem)
                }

            </div>
        );
    }
    renderConfirmRedeem(rec) {
        return (
            <Modal className='redeemView' open={true} >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                        <Icon name='times' style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.setState({ redeem: false })} />
                        <div style={{ fontFamily: 'tfBold', fontSize: '18px' }}>{getName(rec?.clinic, "facilityName")}</div>
                        <div style={{ width: '20px' }} />

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ backgroundColor: '#4d4d4d', height: '2px', width: '80%' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '22px' }}>
                    <img style={{ width: '100px', height: '100px', padding: '8px', objectFit: 'contain' }} src={rec?.clinic?.logoUrl} />
                    <div style={{ fontFamily: 'tfMedium', fontSize: '16px', textAlign: 'center', padding: '8px' }}>{rec?.description}</div>
                    <div style={{ fontSize: '12px', fontFamily: 'tfThin' }}>{rec?.enterCode ? rec?.provider : `Ask ${rec?.clinic?.facilityName} to enter PIN`}</div>


                    {
                        rec?.enterCode ?
                            <Input
                                value={this.state.password}
                                onChange={(e, { value }) => {
                                    this.setState({ password: value }, () => {
                                        if (value.length === 4) {
                                            if (value === rec.password) {
                                                this.redeem(rec)
                                            } else {
                                                this.setState({ password: '' })
                                            }
                                        }
                                    })
                                }} className='' style={{ padding: '20%', color: 'var(--themeColor)', fontSize: '30px' }} transparent placeholder='- - - -' />
                            :
                            <div style={{ padding: '20%', color: 'var(--themeColor)' }}>{rec?.speciality}</div>

                    }
                </div>
                <div style={{ fontSize: '8px', fontFamily: 'tfThin', padding: '8px' }}>
                    <div >*Please present this voucher at the reception to avail the free voucher</div>
                    <div >*Offer can be availed with prior appointments only</div>

                </div>
                <div
                    onClick={() =>
                        this.setState({ redeem: { ...this.state.redeem, enterCode: true } })

                        //this.redeem(rec)
                    }
                    style={{
                        width: '100%',
                        color: 'var(--themeColor)',
                        backgroundColor: '#cacaca',
                        padding: '16px',
                        display: 'flex',
                        fontFamily: 'tfMedium',
                        fontSize: '16px',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}>
                    <div>Redeem</div>

                </div>

            </Modal>
        )

    }
}

export default Vouchers;