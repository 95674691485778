import localForage from "localforage";
import providers from './reducers/providers';
import helpers from './reducers/helpers';
import user from './reducers/user';

import { persistCombineReducers } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage: localForage,
}
const rootReducer = persistCombineReducers(persistConfig, {
  providers: providers,
  helpers,
  user: user
});

export default rootReducer;