import React, { useState, useEffect } from 'react';
import { Icon, Modal } from 'semantic-ui-react'
import Strings from '../strings';

function Popup({ filter, helpers, updateFilter, selectedFilters, switchFlag }) {
    let [selected, setSelected] = useState(selectedFilters)
    let [array, setArray] = useState([])
    let lang = localStorage.getItem('lang')
    useEffect(() => {
        if (filter.key === "lang") {
            array = helpers.languages.sort()
            setArray(array)
        }
        if (filter.key === "nationality") {
            console.log('helpers.nationalities',helpers.nationalities)
            array = helpers.nationalities.sort()
            setArray(array)
        }
        if (filter.key === "gender") {
            array = ['Male', 'Female'];
            setArray(array)
        }
        if (filter.key === "type") {
            array = ['Facilities', 'Doctors'];
            setArray(array)
        }
    }, [])
    return (
        <div>
            <Modal className='popup-modal' style={lang === 'ar' ? { left: 'auto', right: '0' } : { right: 'auto', left: '0' }} open={true}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px', alignItems: 'center', fontFamily: 'tfBold' }}>
                    <div style={{ color: 'red', width: '40px' }} onClick={() => updateFilter({ [filter.key]: false, filter: false })}>
                        {Strings.Reset}
                    </div>
                    <div style={{ padding: '8px', fontSize: '18px', fontFamily: 'tfBold' }}>{Strings[filter.title]}</div>
                    <div style={{ color: 'var(--themeColor)', width: '40px' }} onClick={() => updateFilter({ [filter.key]: selected, filter: false })}>
                        {Strings.OK}
                    </div>
                </div>
                <div style={{ overflow: 'auto', height: 'calc(100% - 50px)' }}>
                    {
                        array.map((rec, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        fontSize: '17px',
                                        padding: '12px 0',
                                        margin: '8px',
                                        borderBottom: '1px solid #cacaca',
                                        cursor: 'pointer',
                                        color: '#4d4d4d',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                    onClick={() => {
                                        if (['lang', 'nationality'].includes(filter.key)) {
                                            if (selected.includes(rec)) {
                                                selected = selected.filter(ite => ite !== rec)
                                                setSelected(selected)
                                            } else {
                                                setSelected([...selected, rec])
                                            }
                                        }
                                        if (filter.key === 'gender') {
                                            updateFilter({ gender: rec.toLowerCase(), filter: false })
                                        }
                                        if (filter.key === 'type') {
                                            switchFlag(rec === 'Facilities' ? 'facilities' : 'doctors')
                                        }
                                    }}
                                >
                                    <div> {Strings[rec]}</div>
                                    {
                                        selected.includes(rec) && <Icon name='checkmark'></Icon>
                                    }

                                </div>
                            )
                        })
                    }

                </div>
            </Modal>
        </div>
    );
}

export default Popup;