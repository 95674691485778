import React, { Component } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import './topSpecs.css'
import '../nearby/nearby.css'
import { Link } from 'react-router-dom'
import Strings from '../../strings'
import { getName } from '../../helpers'

class TopSpecs extends Component {
    render() {
        let { topSpecalties } = this.props
        let slicer = Math.ceil((topSpecalties.length) / 3)
        let optimizedArray = []
        for (var i = 0; i < slicer; i++) {
            let step = 3 * i
            let subArray = topSpecalties.slice(step, step + 3)
            if (subArray.length) {
                optimizedArray.push(subArray)
            }
        }
        return (
            <div className='nearby'>
                <div className='head'>
                    <div className='title'>{Strings.specialties}</div>
                </div>
                <ScrollContainer className='scrollView' >


                    {optimizedArray.map((item, index) => {
                        return (<div style={{ minWidth: optimizedArray.length === 1 ? '100%' : '78%' }} key={index}>
                            {
                                item.map((record, ind) => {
                                    return (
                                        <Link to={'/searchView?search_type=doctors&speciality=' + record.id} className='specsCards' key={ind}>
                                            <div style={{ fontFamily: 'tfMedium', fontSize: '18px', color: '#fff' }}>
                                                {getName(record, "name")}
                                            </div>
                                            <img style={{ width: '20px', height: '20px', objectFit: 'cover' }} src={record.imageUrl} />
                                        </Link>
                                    )
                                })
                            }
                        </div>)

                    })}
                </ScrollContainer>
            </div>
        );
    }
}

export default TopSpecs;