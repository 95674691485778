import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import './header.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Strings from '../../strings';
import { getName } from '../../helpers';
class Header extends Component {

    render() {
        let { doctor, doctorSpec } = this.props
        return (
            <div className='section' style={{ textAlign: 'center' }}>
                <div className='views'>
                    <Icon name='eye'></Icon>
                    {doctor.views} {Strings.views}
                </div>
                <div style={{ padding: '22px 0 0' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '12px' }}>
                        <img className='logo' src={doctor.profilePicture}></img>

                    </div>
                    <h1 className='name'>{getName(doctor, "name")}</h1>
                    <div>{[getName(doctorSpec[0], "name"), (Strings[doctor.title || 'G.P'] )].filter(x => x !== undefined).join(' - ')}</div>
                    <div className='open'>{doctor.recommend?.starCount} {Strings.recommends}</div>
                </div>
            </div>

        );
    }
}

export default Header;